import React, { useState } from 'react';

import "./ServicesSubPages.scss";
import "./sec-2-optimized.scss";
import "./sec-3-optimized.scss";
import "./sec-4-optimized.scss";
import "./sec-5-optimized.scss";


const TesterPage = () => {
	const [tabSelected, selectTab] = useState(null)
	const [sec2TabSelected, selectsec2Tab] = useState(0)
	return (


		<div className='services-subpage'>
			<div className='banner'>
				<div className="vc_row wpb_row vc_row-fluid vc_custom_1504001004464 vc_row-has-fill sc_layouts_row sc_layouts_row_type_normal sc_layouts_hide_on_frontpage scheme_dark">
					<div className="wpb_column vc_column_container vc_col-sm-12 sc_layouts_column sc_layouts_column_align_center sc_layouts_column_icons_position_left">
						<div className="vc_column-inner">
							<div className="wpb_wrapper">
								<div className="vc_empty_space" style={{ height: "5em" }}><span className="vc_empty_space_inner" />
								</div>
								<div id="sc_content_730126100" className="sc_content color_style_default sc_content_default sc_content_width_1_1 sc_float_center">
									<div className="sc_content_container"><div className="sc_layouts_item">
										<div id="sc_layouts_title_933812428" className="sc_layouts_title with_content">
											<div className="sc_layouts_title_content">
												<div className="sc_layouts_title_title">
													<h1 className="sc_layouts_title_caption">Mortgage Services</h1>
												</div>
												{/* <div className="sc_layouts_title_breadcrumbs">
													<div className="breadcrumbs"><a className="breadcrumbs_item home" href="https://antverp.axiomthemes.com/">Home</a>
													<span className="breadcrumbs_delimiter" />
													<span className="breadcrumbs_item current">Services</span>
													<span className="breadcrumbs_delimiter" />
													<span className="breadcrumbs_item current">Mortgage Services</span>
													</div>
												</div> */}

												<div className="sc_layouts_title_breadcrumbs">
													<div className="breadcrumbs">
														<span className="breadcrumbs_delimiter" />
														<span className="breadcrumbs_item current">We make finding the perfect mortgage simple and stress-free. </span>
														
													</div>
												</div>
											</div>

										</div></div>
									</div>
								</div>

								<div className="vc_empty_space" style={{ height: "5em" }}><span className="vc_empty_space_inner" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='sec-2'>
				<div data-elementor-type="wp-page" data-elementor-id="4387" className="elementor elementor-4387">
					<div className="elementor-element elementor-element-2ff5bd3 e-flex e-con-boxed rt-parallax-bg-no e-con e-parent e-lazyloaded" data-id="2ff5bd3" data-element_type="container">
						<div className="e-con-inner">
							<div className="elementor-element elementor-element-8ec54ef e-con-full e-flex rt-parallax-bg-no e-con e-child" data-id="8ec54ef" data-element_type="container">
								<div className="elementor-element elementor-element-f49978a elementor-invisible elementor-widget elementor-widget-rt-title" data-id="f49978a" data-element_type="widget" data-widget_type="rt-title.default">
									<div className="elementor-widget-container">
										<div className="section-title-wrapper ">
											<div className="title-inner-wrapper ah-headline">



												<div className="wow-off fadeInUp" data-wow-delay="400ms" data-wow-duration="1200ms"><h2 className="main-title middle">Customised Mortgage Plans<br/> for Your Home Journey </h2>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="elementor-element elementor-element-f91eed0 e-grid e-con-full rt-parallax-bg-no e-con e-child" data-id="f91eed0" data-element_type="container">
									<div className="elementor-element elementor-element-b259677 elementor-invisible elementor-widget elementor-widget-rt-info-box" data-id="b259677" data-element_type="widget" data-widget_type="rt-info-box.default">
										<div className="elementor-widget-container">
											<div className="rt-info-box rt-info-layout-1" >
												<div className="info-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms" style={{ visibility: "visible", animationDuration: "1200ms", animationDelay: "200ms", animationName: "fadeInUp" }}>
													<div className="info-icon-holder icon-holder">
														<div className="info-icon">
															<i aria-hidden="true" className=" icon-rt-tax-service" />
														</div>
														<h3 className="info-title"><a>First-Time Buyer Mortgage </a>
														</h3>
													</div>
													<div className="info-content-holder">
														<div className="content-holder">
															<p>Buying your first home is a big step! We’ll help you navigate the process, ensuring you get the best deal for your new beginning. </p>
														</div><div className="rt-shape"> <svg width="581" height="510" viewBox="0 0 581 510" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M200.849 0.463867C203.899 0.463867 206.568 1.99659 208.093 4.67887L397.968 331.15C399.493 333.832 399.493 337.281 397.968 339.963L299.599 505.497C296.167 511.245 288.161 510.862 285.11 505.497L1.44234 13.1089C-1.98913 7.36114 2.20489 0.463867 8.68655 0.463867H200.849Z" fill="#A8E6FF" />
															<path d="M571.829 0.463867C578.31 0.463867 582.504 7.36114 579.073 13.1089L481.848 182.858C477.654 190.139 466.597 187.457 466.216 179.027L460.878 76.3338C460.878 73.6516 459.353 71.3525 457.065 69.8198L378.141 16.1743C371.278 11.5761 374.71 0.463867 382.717 0.463867H571.829Z" fill="#A8E6FF" />
														</svg>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="elementor-element elementor-element-b259677 elementor-invisible elementor-widget elementor-widget-rt-info-box" data-id="399a6ce" data-element_type="widget" data-widget_type="rt-info-box.default">
										<div className="elementor-widget-container">
											<div className="rt-info-box rt-info-layout-1" >
												<div className="info-box wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1200ms" style={{ visibility: "visible", animationDuration: "1200ms", animationDelay: "200ms", animationName: "fadeInUp" }}>
													<div className="info-icon-holder icon-holder">
														<div className="info-icon">
															<i aria-hidden="true" className=" icon-rt-secure-data-service" />
														</div>
														<h3 className="info-title"><a>Remortgage </a>
														</h3>
													</div>
													<div className="info-content-holder">
														<div className="content-holder">
															<p>Looking to switch mortgages? We’ll help you find better rates and terms, potentially saving you money in the long run. </p>
														</div><div className="rt-shape"> <svg width="581" height="510" viewBox="0 0 581 510" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M200.849 0.463867C203.899 0.463867 206.568 1.99659 208.093 4.67887L397.968 331.15C399.493 333.832 399.493 337.281 397.968 339.963L299.599 505.497C296.167 511.245 288.161 510.862 285.11 505.497L1.44234 13.1089C-1.98913 7.36114 2.20489 0.463867 8.68655 0.463867H200.849Z" fill="#A8E6FF" />
															<path d="M571.829 0.463867C578.31 0.463867 582.504 7.36114 579.073 13.1089L481.848 182.858C477.654 190.139 466.597 187.457 466.216 179.027L460.878 76.3338C460.878 73.6516 459.353 71.3525 457.065 69.8198L378.141 16.1743C371.278 11.5761 374.71 0.463867 382.717 0.463867H571.829Z" fill="#A8E6FF" />
														</svg>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="elementor-element elementor-element-b259677 elementor-invisible elementor-widget elementor-widget-rt-info-box" data-id="599129a" data-element_type="widget" data-widget_type="rt-info-box.default">
										<div className="elementor-widget-container">
											<div className="rt-info-box rt-info-layout-1" >
												<div className="info-box wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1200ms" style={{ visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp" }}>
													<div className="info-icon-holder icon-holder">
														<div className="info-icon">
															<i aria-hidden="true" className=" icon-rt-data-service" />
														</div>
														<h3 className="info-title"><a>Buy-to-Let Mortgage </a>
														</h3>
													</div>
													<div className="info-content-holder">
														<div className="content-holder">
															<p>Planning to invest in rental properties? Our buy-to-let mortgage options make property investment more straightforward. </p>
														</div><div className="rt-shape"> <svg width="581" height="510" viewBox="0 0 581 510" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M200.849 0.463867C203.899 0.463867 206.568 1.99659 208.093 4.67887L397.968 331.15C399.493 333.832 399.493 337.281 397.968 339.963L299.599 505.497C296.167 511.245 288.161 510.862 285.11 505.497L1.44234 13.1089C-1.98913 7.36114 2.20489 0.463867 8.68655 0.463867H200.849Z" fill="#A8E6FF" />
															<path d="M571.829 0.463867C578.31 0.463867 582.504 7.36114 579.073 13.1089L481.848 182.858C477.654 190.139 466.597 187.457 466.216 179.027L460.878 76.3338C460.878 73.6516 459.353 71.3525 457.065 69.8198L378.141 16.1743C371.278 11.5761 374.71 0.463867 382.717 0.463867H571.829Z" fill="#A8E6FF" />
														</svg>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="elementor-element elementor-element-6a44201 elementor-invisible elementor-widget elementor-widget-rt-info-box" data-id="6a44201" data-element_type="widget" data-widget_type="rt-info-box.default">
										<div className="elementor-widget-container">
											<div className="rt-info-box rt-info-layout-1" >
												<div className="info-box wow fadeInUp" data-wow-delay="1000ms" data-wow-duration="1200ms" style={{ visibility: "visible", animationDuration: "1200ms", animationDelay: "1000ms", animationName: "fadeInUp" }}>
													<div className="info-icon-holder icon-holder">
														<div className="info-icon">
															<i aria-hidden="true" className=" icon-rt-digital-art" />
														</div>
														<h3 className="info-title"><a>New Build Mortgage </a>
														</h3>
													</div>
													<div className="info-content-holder">
														<div className="content-holder">
															<p>Purchasing a newly built home? We’ll guide you through the specialised mortgage process with ease and confidence. </p>
														</div><div className="rt-shape"> <svg width="581" height="510" viewBox="0 0 581 510" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M200.849 0.463867C203.899 0.463867 206.568 1.99659 208.093 4.67887L397.968 331.15C399.493 333.832 399.493 337.281 397.968 339.963L299.599 505.497C296.167 511.245 288.161 510.862 285.11 505.497L1.44234 13.1089C-1.98913 7.36114 2.20489 0.463867 8.68655 0.463867H200.849Z" fill="#A8E6FF" />
															<path d="M571.829 0.463867C578.31 0.463867 582.504 7.36114 579.073 13.1089L481.848 182.858C477.654 190.139 466.597 187.457 466.216 179.027L460.878 76.3338C460.878 73.6516 459.353 71.3525 457.065 69.8198L378.141 16.1743C371.278 11.5761 374.71 0.463867 382.717 0.463867H571.829Z" fill="#A8E6FF" />
														</svg>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="elementor-element elementor-element-5d7af6d elementor-invisible elementor-widget elementor-widget-rt-info-box" data-id="5d7af6d" data-element_type="widget" data-widget_type="rt-info-box.default">
										<div className="elementor-widget-container">
											<div className="rt-info-box rt-info-layout-1">
												<div className="info-box wow fadeInUp" data-wow-delay="1200ms" data-wow-duration="1200ms" style={{ visibility: "visible", animationDuration: "1200ms", animationDelay: "1200ms", animationName: "fadeInUp" }}>
													<div className="info-icon-holder icon-holder">
														<div className="info-icon">
															<i aria-hidden="true" className=" icon-rt-dollar-1" />
														</div>
														<h3 className="info-title"><a>Shared Ownership Mortgage </a>
														</h3>
													</div>
													<div className="info-content-holder">
														<div className="content-holder">
															<p>Want to own part of your home? We offer shared ownership mortgages to make homeownership more accessible and affordable. </p>
														</div><div className="rt-shape"> <svg width="581" height="510" viewBox="0 0 581 510" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M200.849 0.463867C203.899 0.463867 206.568 1.99659 208.093 4.67887L397.968 331.15C399.493 333.832 399.493 337.281 397.968 339.963L299.599 505.497C296.167 511.245 288.161 510.862 285.11 505.497L1.44234 13.1089C-1.98913 7.36114 2.20489 0.463867 8.68655 0.463867H200.849Z" fill="#A8E6FF" />
															<path d="M571.829 0.463867C578.31 0.463867 582.504 7.36114 579.073 13.1089L481.848 182.858C477.654 190.139 466.597 187.457 466.216 179.027L460.878 76.3338C460.878 73.6516 459.353 71.3525 457.065 69.8198L378.141 16.1743C371.278 11.5761 374.71 0.463867 382.717 0.463867H571.829Z" fill="#A8E6FF" />
														</svg>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="elementor-element elementor-element-28a9303 elementor-invisible elementor-widget elementor-widget-rt-info-box" data-id="28a9303" data-element_type="widget" data-widget_type="rt-info-box.default">
										<div className="elementor-widget-container">
											<div className="rt-info-box rt-info-layout-1" >
												<div className="info-box wow fadeInUp" data-wow-delay="1400ms" data-wow-duration="1200ms" style={{ visibility: "visible", animationDuration: "1200ms", animationDelay: "1400ms", animationName: "fadeInUp" }}>
													<div className="info-icon-holder icon-holder">
														<div className="info-icon">
															<i aria-hidden="true" className=" icon-rt-trust-badge" />
														</div>
														<h3 className="info-title"><a> Interest-Only Mortgage </a>
														</h3>
													</div>
													<div className="info-content-holder">
														<div className="content-holder">
															<p>Looking for lower monthly payments? With our interest-only mortgage, you can pay just the interest, with flexible repayment options. </p>
														</div><div className="rt-shape"> <svg width="581" height="510" viewBox="0 0 581 510" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M200.849 0.463867C203.899 0.463867 206.568 1.99659 208.093 4.67887L397.968 331.15C399.493 333.832 399.493 337.281 397.968 339.963L299.599 505.497C296.167 511.245 288.161 510.862 285.11 505.497L1.44234 13.1089C-1.98913 7.36114 2.20489 0.463867 8.68655 0.463867H200.849Z" fill="#A8E6FF" />
															<path d="M571.829 0.463867C578.31 0.463867 582.504 7.36114 579.073 13.1089L481.848 182.858C477.654 190.139 466.597 187.457 466.216 179.027L460.878 76.3338C460.878 73.6516 459.353 71.3525 457.065 69.8198L378.141 16.1743C371.278 11.5761 374.71 0.463867 382.717 0.463867H571.829Z" fill="#A8E6FF" />
														</svg>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>

			<div className='sec-3'>
				<div className='elementor elementor-43'>
					<div className="elementor-element elementor-element-5328b33 e-flex e-con-boxed rt-parallax-bg-no e-con e-parent e-lazyloaded" data-id="5328b33" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div className="e-con-inner">
							<div className="elementor-element elementor-element-f8a9612 e-con-full e-flex rt-parallax-bg-no e-con e-child" data-id="f8a9612" data-element_type="container">
								<div className="elementor-element elementor-element-0a59994 elementor-invisible elementor-widget elementor-widget-rt-title" data-id="0a59994" data-element_type="widget" data-widget_type="rt-title.default">
									<div className="elementor-widget-container">
										<div className="section-title-wrapper ">
											<div className="title-inner-wrapper ah-headline">



												<div className="wow-off fadeInUp" data-wow-delay="400ms" data-wow-duration="1200ms"><h2 className="main-title middle">Why Choose Us for Your Mortgage Needs? </h2>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="elementor-element elementor-element-fd95ffc elementor-invisible elementor-widget elementor-widget-rt-tab" data-id="fd95ffc" data-element_type="widget" data-widget_type="rt-tab.default">
									<div className="elementor-widget-container">
										<div className="rt-tab-block rt-tab-layout-2">
											<div className="tab-block wow-off fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
												<ul className="tab-block-tabs">
													<li className={`tab-block-tab ${sec2TabSelected === 0 ?"is-active":""}`} onClick={()=>selectsec2Tab(0)} >
														<i className=" icon-rt-secure-data-service" />Expert Guidance
													</li>
													<li className={`tab-block-tab ${sec2TabSelected === 1 ?"is-active":""}`} onClick={()=>selectsec2Tab(0)}>
														<i className=" icon-rt-dollar-1" />Expert Guidance
													</li>
													<li className={`tab-block-tab ${sec2TabSelected === 2 ?"is-active":""}`} onClick={()=>selectsec2Tab(0)}>
														<i className=" icon-rt-data-service" />Expert Guidance
													</li>
												</ul>
												<div className="tab-block-content">
													
													<div className="tab-block-pane" style={sec2TabSelected === 0 ? { display: 'block' } : { display: 'none' }}>
														<p>We provide personalised, expert advice to guide you through every step of the mortgage process, ensuring you make confident decisions.</p>
														<div className="feature-wrap">
															<ul className="feature-list">
																<li>Personalised support </li>
																<li>Clear communication </li>
																<li>Hassle-free experience </li>
															</ul>
															<div className="chart-info">
																<img decoding="async" data-savepage-src="/images/services-subpages/mortgage-services/sec-3-graph.png" src="/images/services-subpages/mortgage-services/sec-3-graph.png"></img>
															</div>
														</div>
													</div>
													<div className="tab-block-pane" style={sec2TabSelected === 1 ? { display: 'block' } : { display: 'none' }}>
														<p>Our team works hard to find you the most competitive rates, saving you money and reducing the stress of mortgage payments.</p>
														<div className="feature-wrap">
															<ul className="feature-list">
																<li>    Low-interest rates </li>
																<li>    Flexible options</li>
																<li>    No hidden fees </li>
															</ul>
															<div className="chart-info">
																<img decoding="async" data-savepage-src="https://www.radiustheme.com/demo/wordpress/themes/finwave/wp-content/uploads/2024/07/element-8.png" src="" alt="chart" width="203" height="100" data-savepage-loading="lazy"></img>
															</div>
														</div>
														</div>
														<div className="tab-block-pane" style={sec2TabSelected === 2 ? { display: 'block' } : { display: 'none' }}>
														<p>Whether you're buying, remortgaging, or investing, we offer mortgage solutions customised to fit your unique financial situation. </p>
														<div className="feature-wrap">
															<ul className="feature-list">
																<li>    Flexible terms </li>
																<li>    Custom mortgage plans </li>
																<li>    Options for all buyers </li>
															</ul>
															<div className="chart-info">
																<img decoding="async" data-savepage-src="" src="" alt="chart" width="203" height="100" data-savepage-loading="lazy"></img>
															</div>
														</div>
														</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="elementor-element elementor-element-e24efa9 e-con-full e-flex rt-parallax-bg-no e-con e-child" data-id="e24efa9" data-element_type="container">
								<div className="elementor-element elementor-element-1f160cf elementor-widget elementor-widget-rt-image" data-id="1f160cf" data-element_type="widget" data-widget_type="rt-image.default">
									<div className="elementor-widget-container">
										<div className="rt-image-layout rt-image-layout-1 ">
											<div className="rt-image wow bounceInUp" data-wow-delay="200ms" data-wow-duration="1200ms" style={{ visibility: "visible", animationDuration: "1200ms", animationDelay: "200ms", animationName: "bounceInUp" }}>
												<div style={{ transform: "translate3d(25.365px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)" }}>
													<img decoding="async" width="696" height="490" data-savepage-src="https://www.radiustheme.com/demo/wordpress/themes/finwave/wp-content/uploads/2024/07/about-09.jpg" src="/images/services-subpages/mortgage-services/sec-3.jpg"></img>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='sec-4'>
				<div data-elementor-type="wp-page" data-elementor-id="6074" className="elementor elementor-6074">
					<div
						className="elementor-element elementor-element-3f1bdee e-flex e-con-boxed rt-parallax-bg-no e-con e-parent e-lazyloaded"
						data-id="3f1bdee" data-element_type="container">
						<div className="e-con-inner">
							<div className="elementor-element elementor-element-e75def9 e-con-full e-flex rt-parallax-bg-no e-con e-child"
								data-id="e75def9" data-element_type="container">
								<div
									className="elementor-element elementor-element-d0b6c21 elementor-invisible elementor-widget elementor-widget-rt-image"
									data-id="d0b6c21" data-element_type="widget" data-widget_type="rt-image.default">
									<div className="elementor-widget-container">
										<div className="rt-image-layout rt-image-layout-2 ">
											<div className="rt-image wow-off fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms"
												style={{ position: "relative", zIndex: 1 }}>
												<span className="rt-img default" data-parallax="{&quot;y&quot; : , &quot;x&quot; : }"
													style={{ animationDuration: "15s" }}> <img decoding="async" width="819" height="819"
														data-savepage-src="/images/serviceses-subpages/mortgage-services/sec-4.png"
														src="/images/services-subpages/mortgage-services/sec-4.png"
														className="attachment-full size-full" alt=""
														data-savepage-srcset="/images/serviceses-subpages/mortgage-services/sec-4.png 819w, https://www.radiustheme.com/demo/wordpress/themes/finwave/wp-content/uploads/2024/07/shape-bg-1-300x300.jpg 300w, https://www.radiustheme.com/demo/wordpress/themes/finwave/wp-content/uploads/2024/07/shape-bg-1-150x150.jpg 150w, https://www.radiustheme.com/demo/wordpress/themes/finwave/wp-content/uploads/2024/07/shape-bg-1-768x768.jpg 768w, https://www.radiustheme.com/demo/wordpress/themes/finwave/wp-content/uploads/2024/07/shape-bg-1-450x450.jpg 450w"
														srcset="" sizes="(max-width: 819px) 100vw, 819px" data-savepage-loading="lazy" />
												</span>
											</div>
										</div>
									</div>
								</div>
								<div
									className="elementor-element elementor-element-6aa1a2b elementor-absolute elementor-widget elementor-widget-rt-image"
									data-id="6aa1a2b" data-element_type="widget"
									data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="rt-image.default">
									<div className="elementor-widget-container">
										<div className="rt-image-layout rt-image-layout-1 center">
											<div className="rt-image wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms"
												style={{ visibility: "visible", animationDuration: "1200ms", animationDelay: "200ms", animationName: "fadeInUp" }}>
												<div data-parallax="{&quot;y&quot; : 100, &quot;x&quot; : 0}"
													style={{ transform: "translate3d(0px, 67.58px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1))" }}>
													<img decoding="async" width="758" height="862"
														data-savepage-src="https://www.radiustheme.com/demo/wordpress/themes/finwave/wp-content/uploads/2024/07/support.png"
														src=""
														className="attachment-full size-full" alt=""
														data-savepage-srcset="https://www.radiustheme.com/demo/wordpress/themes/finwave/wp-content/uploads/2024/07/support.png 758w, https://www.radiustheme.com/demo/wordpress/themes/finwave/wp-content/uploads/2024/07/support-264x300.png 264w, https://www.radiustheme.com/demo/wordpress/themes/finwave/wp-content/uploads/2024/07/support-600x682.png 600w"
														srcset="" sizes="(max-width: 758px) 100vw, 758px" data-savepage-loading="lazy" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="elementor-element elementor-element-c455ecf e-con-full e-flex rt-parallax-bg-no e-con e-child"
								data-id="c455ecf" data-element_type="container">
								<div
									className="elementor-element elementor-element-9b10626 elementor-invisible elementor-widget elementor-widget-rt-title"
									data-id="9b10626" data-element_type="widget" data-widget_type="rt-title.default">
									<div className="elementor-widget-container">
										<div className="section-title-wrapper ">
											<div className="title-inner-wrapper ah-headline">



												<div className="wow-off fadeInUp" data-wow-delay="400ms" data-wow-duration="1200ms">
													<h2 className="main-title title-gradient-animation no no middle ">Let’s Make Your Dream Home Happen!</h2>
												</div>

												<div className="description wow-off fadeInUp" data-wow-delay="600ms" data-wow-duration="1200ms">
													<p>Got questions or ready to take the next step? Reach out to us! Our friendly mortgage experts are here to help you on your journey to owning your dream home.</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div
									className="elementor-element elementor-element-8b6e03a elementor-invisible elementor-widget elementor-widget-rt-button"
									data-id="8b6e03a" data-element_type="widget" data-widget_type="rt-button.default">
									<div className="elementor-widget-container">
										<div className="rt-button wow-off fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms"> <a
											className="btn button-2 " href="#" target="_blank" rel="nofollow" aria-label="button link"> Talk to an Advisor 
											<i className="icon-rt-right-arrow">
												<svg width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M16.1123 14.1123L16.7472 14.7472L24.0942 7.40016L16.7472 0.0531006L16.1123 0.688032L22.3709 6.94664H0.783203V7.85368H22.3709L16.1123 14.1123Z" fill="white" />
												</svg>
											</i> </a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='sec-5'>
				<div data-elementor-type="wp-page" data-elementor-id="94" className="elementor elementor-94">
					<div className="elementor-element elementor-element-1bb2f45 e-flex e-con-boxed rt-parallax-bg-no e-con e-parent e-lazyloaded" data-id="1bb2f45" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div className="e-con-inner">
							<div className="elementor-element elementor-element-07292ce e-flex e-con-boxed rt-parallax-bg-no e-con e-child" data-id="07292ce" data-element_type="container">
								<div className="e-con-inner">
									<div className="elementor-element elementor-element-4cccb55 elementor-invisible elementor-widget elementor-widget-rt-title" data-id="4cccb55" data-element_type="widget" data-widget_type="rt-title.default">
										<div className="elementor-widget-container">
											<div className="section-title-wrapper ">
												<div className="title-inner-wrapper ah-headline">



													<div className="wow-off fadeInUp" data-wow-delay="400ms" data-wow-duration="1200ms"><h2 className="main-title middle">Let’s Clear Up Your Mortgage Queries! </h2>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="elementor-element elementor-element-d486b67 faq-style-1 elementor-widget elementor-widget-accordion" data-id="d486b67" data-element_type="widget" data-widget_type="accordion.default">
										<div className="elementor-widget-container">
											<div className="elementor-accordion">
												<div className="elementor-accordion-item" onClick={() => selectTab(0)}>
													<div id="elementor-tab-title-2221" className={`elementor-tab-title ${tabSelected === 0 ? 'elementor-active' : ''}`} data-tab="1" role="button" aria-controls="elementor-tab-content-2221" aria-expanded="true" tabindex="0" aria-selected="true">
														<span className="elementor-accordion-icon elementor-accordion-icon-right" aria-hidden="true"> <span className="elementor-accordion-icon-closed"><svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" clip-rule="evenodd" d="M20.9805 0.16333C32.0258 0.16333 40.9805 9.118 40.9805 20.1633C40.9805 31.2087 32.0258 40.1633 20.9805 40.1633C9.93514 40.1633 0.980469 31.2087 0.980469 20.1633C0.980469 9.118 9.93514 0.16333 20.9805 0.16333ZM19.6471 12.3856V18.83H13.2027C11.4475 18.83 11.4475 21.4967 13.2027 21.4967H19.6471V27.9411C19.6471 29.6963 22.3138 29.6963 22.3138 27.9411V21.4967H28.7582C30.5134 21.4967 30.5134 18.83 28.7582 18.83H22.3138V12.3856C22.3138 10.6304 19.6471 10.6304 19.6471 12.3856ZM33.2374 7.90644C26.4685 1.13755 15.4925 1.13755 8.72536 7.90644C1.95647 14.6753 1.95647 25.6513 8.72536 32.4184C15.4942 39.1873 26.4702 39.1873 33.2374 32.4184C40.0062 25.6509 40.0062 14.6753 33.2374 7.90644Z" fill="#050C9B" />
														</svg>
														</span>
															<span className="elementor-accordion-icon-opened">
																<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path fill-rule="evenodd" clip-rule="evenodd" d="M20.9805 0.16333C32.0258 0.16333 40.9805 9.118 40.9805 20.1633C40.9805 31.2087 32.0258 40.1633 20.9805 40.1633C9.93514 40.1633 0.980469 31.2087 0.980469 20.1633C0.980469 9.118 9.93514 0.16333 20.9805 0.16333ZM19.6471 12.3856V18.83H13.2027C11.4475 18.83 11.4475 21.4967 13.2027 21.4967H19.6471V27.9411C19.6471 29.6963 22.3138 29.6963 22.3138 27.9411V21.4967H28.7582C30.5134 21.4967 30.5134 18.83 28.7582 18.83H22.3138V12.3856C22.3138 10.6304 19.6471 10.6304 19.6471 12.3856ZM33.2374 7.90644C26.4685 1.13755 15.4925 1.13755 8.72536 7.90644C1.95647 14.6753 1.95647 25.6513 8.72536 32.4184C15.4942 39.1873 26.4702 39.1873 33.2374 32.4184C40.0062 25.6509 40.0062 14.6753 33.2374 7.90644Z" fill="#050C9B" />
																</svg>

															</span>
														</span> <a className="elementor-accordion-title" tabindex="0">Is it possible to secure a buy-to-let mortgage with a low deposit?</a>
													</div>
													<div id="elementor-tab-content-2221" className={`elementor-tab-content elementor-clearfix ${tabSelected === 0 ? 'elementor-active' : ''}`} data-tab="1" role="region" aria-labelledby="elementor-tab-title-2221" style={tabSelected === 0 ? { display: 'block' } : { display: 'none' }}>
														<p>Yes! We offer various options for buy-to-let mortgages, including low deposit plans. Our experts will help you find the best fit for your investment goals.</p>
													</div>
												</div>
												<div className="elementor-accordion-item" onClick={() => selectTab(1)}>
													<div id="elementor-tab-title-2222" className={`elementor-tab-title ${tabSelected === 1 ? 'elementor-active' : ''}`} data-tab="2" role="button" aria-controls="elementor-tab-content-2222" aria-expanded="false">
														<span className="elementor-accordion-icon elementor-accordion-icon-right" aria-hidden="true">
															<span className="elementor-accordion-icon-closed">
																<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path fill-rule="evenodd" clip-rule="evenodd" d="M20.9805 0.16333C32.0258 0.16333 40.9805 9.118 40.9805 20.1633C40.9805 31.2087 32.0258 40.1633 20.9805 40.1633C9.93514 40.1633 0.980469 31.2087 0.980469 20.1633C0.980469 9.118 9.93514 0.16333 20.9805 0.16333ZM19.6471 12.3856V18.83H13.2027C11.4475 18.83 11.4475 21.4967 13.2027 21.4967H19.6471V27.9411C19.6471 29.6963 22.3138 29.6963 22.3138 27.9411V21.4967H28.7582C30.5134 21.4967 30.5134 18.83 28.7582 18.83H22.3138V12.3856C22.3138 10.6304 19.6471 10.6304 19.6471 12.3856ZM33.2374 7.90644C26.4685 1.13755 15.4925 1.13755 8.72536 7.90644C1.95647 14.6753 1.95647 25.6513 8.72536 32.4184C15.4942 39.1873 26.4702 39.1873 33.2374 32.4184C40.0062 25.6509 40.0062 14.6753 33.2374 7.90644Z" fill="#050C9B" />
																</svg>

															</span> <span className="elementor-accordion-icon-opened"><svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" clip-rule="evenodd" d="M20.9805 0.16333C32.0258 0.16333 40.9805 9.118 40.9805 20.1633C40.9805 31.2087 32.0258 40.1633 20.9805 40.1633C9.93514 40.1633 0.980469 31.2087 0.980469 20.1633C0.980469 9.118 9.93514 0.16333 20.9805 0.16333ZM19.6471 12.3856V18.83H13.2027C11.4475 18.83 11.4475 21.4967 13.2027 21.4967H19.6471V27.9411C19.6471 29.6963 22.3138 29.6963 22.3138 27.9411V21.4967H28.7582C30.5134 21.4967 30.5134 18.83 28.7582 18.83H22.3138V12.3856C22.3138 10.6304 19.6471 10.6304 19.6471 12.3856ZM33.2374 7.90644C26.4685 1.13755 15.4925 1.13755 8.72536 7.90644C1.95647 14.6753 1.95647 25.6513 8.72536 32.4184C15.4942 39.1873 26.4702 39.1873 33.2374 32.4184C40.0062 25.6509 40.0062 14.6753 33.2374 7.90644Z" fill="#050C9B" />
															</svg>
															</span>
														</span> <a className="elementor-accordion-title" tabindex="0">How does a shared ownership mortgage work?</a>
													</div>
													<div id="elementor-tab-content-2222" className={`elementor-tab-content elementor-clearfix ${tabSelected === 1 ? 'elementor-active' : ''}`} data-tab="2" role="region" aria-labelledby="elementor-tab-title-2222" style={tabSelected === 1 ? { display: 'block' } : { display: 'none' }}>
														<p>With shared ownership, you buy a portion of a property and pay rent on the rest. We’ll help you understand the process and find the right plan for you! </p></div>
												</div>
												<div className="elementor-accordion-item" onClick={() => selectTab(2)}>
													<div id="elementor-tab-title-2223" className={`elementor-tab-title ${tabSelected === 2 ? 'elementor-active' : ''}`} data-tab="3" role="button" aria-controls="elementor-tab-content-2223" aria-expanded="false">
														<span className="elementor-accordion-icon elementor-accordion-icon-right" aria-hidden="true">
															<span className="elementor-accordion-icon-closed"><svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" clip-rule="evenodd" d="M20.9805 0.16333C32.0258 0.16333 40.9805 9.118 40.9805 20.1633C40.9805 31.2087 32.0258 40.1633 20.9805 40.1633C9.93514 40.1633 0.980469 31.2087 0.980469 20.1633C0.980469 9.118 9.93514 0.16333 20.9805 0.16333ZM19.6471 12.3856V18.83H13.2027C11.4475 18.83 11.4475 21.4967 13.2027 21.4967H19.6471V27.9411C19.6471 29.6963 22.3138 29.6963 22.3138 27.9411V21.4967H28.7582C30.5134 21.4967 30.5134 18.83 28.7582 18.83H22.3138V12.3856C22.3138 10.6304 19.6471 10.6304 19.6471 12.3856ZM33.2374 7.90644C26.4685 1.13755 15.4925 1.13755 8.72536 7.90644C1.95647 14.6753 1.95647 25.6513 8.72536 32.4184C15.4942 39.1873 26.4702 39.1873 33.2374 32.4184C40.0062 25.6509 40.0062 14.6753 33.2374 7.90644Z" fill="#050C9B" />
															</svg>

															</span> <span className="elementor-accordion-icon-opened"><svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" clip-rule="evenodd" d="M20.9805 0.16333C32.0258 0.16333 40.9805 9.118 40.9805 20.1633C40.9805 31.2087 32.0258 40.1633 20.9805 40.1633C9.93514 40.1633 0.980469 31.2087 0.980469 20.1633C0.980469 9.118 9.93514 0.16333 20.9805 0.16333ZM19.6471 12.3856V18.83H13.2027C11.4475 18.83 11.4475 21.4967 13.2027 21.4967H19.6471V27.9411C19.6471 29.6963 22.3138 29.6963 22.3138 27.9411V21.4967H28.7582C30.5134 21.4967 30.5134 18.83 28.7582 18.83H22.3138V12.3856C22.3138 10.6304 19.6471 10.6304 19.6471 12.3856ZM33.2374 7.90644C26.4685 1.13755 15.4925 1.13755 8.72536 7.90644C1.95647 14.6753 1.95647 25.6513 8.72536 32.4184C15.4942 39.1873 26.4702 39.1873 33.2374 32.4184C40.0062 25.6509 40.0062 14.6753 33.2374 7.90644Z" fill="#050C9B" />
															</svg>
															</span>
														</span> <a className="elementor-accordion-title" tabindex="0">What makes your mortgage services different from others?</a>
													</div><div id="elementor-tab-content-2223" className={`elementor-tab-content elementor-clearfix ${tabSelected === 2 ? 'elementor-active' : ''}`} data-tab="3" role="region" aria-labelledby="elementor-tab-title-2223" style={tabSelected === 2 ? { display: 'block' } : { display: 'none' }}>
														<p>We focus on personalised service, transparency, and building relationships. Our goal is to ensure you feel supported and informed throughout your mortgage journey.</p></div>
												</div>
												<div className="elementor-accordion-item" onClick={() => selectTab(3)}>
													<div id="elementor-tab-title-2224" className={`elementor-tab-title ${tabSelected === 3 ? 'elementor-active' : ''}`} data-tab="4" role="button" aria-controls="elementor-tab-content-2224" aria-expanded="false">
														<span className="elementor-accordion-icon elementor-accordion-icon-right" aria-hidden="true">
															<span className="elementor-accordion-icon-closed"><svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" clip-rule="evenodd" d="M20.9805 0.16333C32.0258 0.16333 40.9805 9.118 40.9805 20.1633C40.9805 31.2087 32.0258 40.1633 20.9805 40.1633C9.93514 40.1633 0.980469 31.2087 0.980469 20.1633C0.980469 9.118 9.93514 0.16333 20.9805 0.16333ZM19.6471 12.3856V18.83H13.2027C11.4475 18.83 11.4475 21.4967 13.2027 21.4967H19.6471V27.9411C19.6471 29.6963 22.3138 29.6963 22.3138 27.9411V21.4967H28.7582C30.5134 21.4967 30.5134 18.83 28.7582 18.83H22.3138V12.3856C22.3138 10.6304 19.6471 10.6304 19.6471 12.3856ZM33.2374 7.90644C26.4685 1.13755 15.4925 1.13755 8.72536 7.90644C1.95647 14.6753 1.95647 25.6513 8.72536 32.4184C15.4942 39.1873 26.4702 39.1873 33.2374 32.4184C40.0062 25.6509 40.0062 14.6753 33.2374 7.90644Z" fill="#050C9B" />
															</svg>

															</span> <span className="elementor-accordion-icon-opened"><svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" clip-rule="evenodd" d="M20.9805 0.16333C32.0258 0.16333 40.9805 9.118 40.9805 20.1633C40.9805 31.2087 32.0258 40.1633 20.9805 40.1633C9.93514 40.1633 0.980469 31.2087 0.980469 20.1633C0.980469 9.118 9.93514 0.16333 20.9805 0.16333ZM19.6471 12.3856V18.83H13.2027C11.4475 18.83 11.4475 21.4967 13.2027 21.4967H19.6471V27.9411C19.6471 29.6963 22.3138 29.6963 22.3138 27.9411V21.4967H28.7582C30.5134 21.4967 30.5134 18.83 28.7582 18.83H22.3138V12.3856C22.3138 10.6304 19.6471 10.6304 19.6471 12.3856ZM33.2374 7.90644C26.4685 1.13755 15.4925 1.13755 8.72536 7.90644C1.95647 14.6753 1.95647 25.6513 8.72536 32.4184C15.4942 39.1873 26.4702 39.1873 33.2374 32.4184C40.0062 25.6509 40.0062 14.6753 33.2374 7.90644Z" fill="#050C9B" />
															</svg>
															</span>
														</span> <a className="elementor-accordion-title" tabindex="0">Can I get a mortgage if I have bad credit?</a>
													</div><div id="elementor-tab-content-2224" className={`elementor-tab-content elementor-clearfix ${tabSelected === 3 ? 'elementor-active' : ''}`} data-tab="4" role="region" aria-labelledby="elementor-tab-title-2224" style={tabSelected === 3 ? { display: 'block' } : { display: 'none' }}>
														<p>Yes, we have options for those with less-than-perfect credit. Our team will work closely with you to find solutions that suit your financial situation.</p></div>
												</div>
												<div className="elementor-accordion-item">
													<div id="elementor-tab-title-2225" className={`elementor-tab-title ${tabSelected === 4 ? 'elementor-active' : ''}`} data-tab="5" role="button" aria-controls="elementor-tab-content-2225" aria-expanded="false">
														<span className="elementor-accordion-icon elementor-accordion-icon-right" aria-hidden="true">
															<span className="elementor-accordion-icon-closed"><svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" clip-rule="evenodd" d="M20.9805 0.16333C32.0258 0.16333 40.9805 9.118 40.9805 20.1633C40.9805 31.2087 32.0258 40.1633 20.9805 40.1633C9.93514 40.1633 0.980469 31.2087 0.980469 20.1633C0.980469 9.118 9.93514 0.16333 20.9805 0.16333ZM19.6471 12.3856V18.83H13.2027C11.4475 18.83 11.4475 21.4967 13.2027 21.4967H19.6471V27.9411C19.6471 29.6963 22.3138 29.6963 22.3138 27.9411V21.4967H28.7582C30.5134 21.4967 30.5134 18.83 28.7582 18.83H22.3138V12.3856C22.3138 10.6304 19.6471 10.6304 19.6471 12.3856ZM33.2374 7.90644C26.4685 1.13755 15.4925 1.13755 8.72536 7.90644C1.95647 14.6753 1.95647 25.6513 8.72536 32.4184C15.4942 39.1873 26.4702 39.1873 33.2374 32.4184C40.0062 25.6509 40.0062 14.6753 33.2374 7.90644Z" fill="#050C9B" />
															</svg>

															</span> <span className="elementor-accordion-icon-opened"><svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" clip-rule="evenodd" d="M20.9805 0.16333C32.0258 0.16333 40.9805 9.118 40.9805 20.1633C40.9805 31.2087 32.0258 40.1633 20.9805 40.1633C9.93514 40.1633 0.980469 31.2087 0.980469 20.1633C0.980469 9.118 9.93514 0.16333 20.9805 0.16333ZM19.6471 12.3856V18.83H13.2027C11.4475 18.83 11.4475 21.4967 13.2027 21.4967H19.6471V27.9411C19.6471 29.6963 22.3138 29.6963 22.3138 27.9411V21.4967H28.7582C30.5134 21.4967 30.5134 18.83 28.7582 18.83H22.3138V12.3856C22.3138 10.6304 19.6471 10.6304 19.6471 12.3856ZM33.2374 7.90644C26.4685 1.13755 15.4925 1.13755 8.72536 7.90644C1.95647 14.6753 1.95647 25.6513 8.72536 32.4184C15.4942 39.1873 26.4702 39.1873 33.2374 32.4184C40.0062 25.6509 40.0062 14.6753 33.2374 7.90644Z" fill="#050C9B" />
															</svg>
															</span>
														</span> <a className="elementor-accordion-title" tabindex="0">How can I lower my mortgage payments?</a>
													</div><div id="elementor-tab-content-2225" className={`elementor-tab-content elementor-clearfix ${tabSelected === 4 ? 'elementor-active' : ''}`} data-tab="5" role="region" aria-labelledby="elementor-tab-title-2225" style={tabSelected === 4 ? { display: 'block' } : { display: 'none' }}>
														<p>Options like refinancing, extending your term, or exploring government assistance programs can help. Our team can discuss strategies tailored to your needs!</p></div>
												</div>
												<div className="elementor-accordion-item" onClick={() => selectTab(5)}>
													<div id="elementor-tab-title-2226" className={`elementor-tab-title ${tabSelected === 5 ? 'elementor-active' : ''}`} data-tab="6" role="button" aria-controls="elementor-tab-content-2226" aria-expanded="false">
														<span className="elementor-accordion-icon elementor-accordion-icon-right" aria-hidden="true">
															<span className="elementor-accordion-icon-closed"><svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" clip-rule="evenodd" d="M20.9805 0.16333C32.0258 0.16333 40.9805 9.118 40.9805 20.1633C40.9805 31.2087 32.0258 40.1633 20.9805 40.1633C9.93514 40.1633 0.980469 31.2087 0.980469 20.1633C0.980469 9.118 9.93514 0.16333 20.9805 0.16333ZM19.6471 12.3856V18.83H13.2027C11.4475 18.83 11.4475 21.4967 13.2027 21.4967H19.6471V27.9411C19.6471 29.6963 22.3138 29.6963 22.3138 27.9411V21.4967H28.7582C30.5134 21.4967 30.5134 18.83 28.7582 18.83H22.3138V12.3856C22.3138 10.6304 19.6471 10.6304 19.6471 12.3856ZM33.2374 7.90644C26.4685 1.13755 15.4925 1.13755 8.72536 7.90644C1.95647 14.6753 1.95647 25.6513 8.72536 32.4184C15.4942 39.1873 26.4702 39.1873 33.2374 32.4184C40.0062 25.6509 40.0062 14.6753 33.2374 7.90644Z" fill="#050C9B" />
															</svg>

															</span> <span className="elementor-accordion-icon-opened"><svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" clip-rule="evenodd" d="M20.9805 0.16333C32.0258 0.16333 40.9805 9.118 40.9805 20.1633C40.9805 31.2087 32.0258 40.1633 20.9805 40.1633C9.93514 40.1633 0.980469 31.2087 0.980469 20.1633C0.980469 9.118 9.93514 0.16333 20.9805 0.16333ZM19.6471 12.3856V18.83H13.2027C11.4475 18.83 11.4475 21.4967 13.2027 21.4967H19.6471V27.9411C19.6471 29.6963 22.3138 29.6963 22.3138 27.9411V21.4967H28.7582C30.5134 21.4967 30.5134 18.83 28.7582 18.83H22.3138V12.3856C22.3138 10.6304 19.6471 10.6304 19.6471 12.3856ZM33.2374 7.90644C26.4685 1.13755 15.4925 1.13755 8.72536 7.90644C1.95647 14.6753 1.95647 25.6513 8.72536 32.4184C15.4942 39.1873 26.4702 39.1873 33.2374 32.4184C40.0062 25.6509 40.0062 14.6753 33.2374 7.90644Z" fill="#050C9B" />
															</svg>
															</span>
														</span> <a className="elementor-accordion-title" tabindex="0">What benefits come with being pre-approved for a mortgage?</a>
													</div><div id="elementor-tab-content-2226" className={`elementor-tab-content elementor-clearfix ${tabSelected === 5 ? 'elementor-active' : ''}`} data-tab="6" role="region" aria-labelledby="elementor-tab-title-2226" style={tabSelected === 5 ? { display: 'block' } : { display: 'none' }}>
														<p>Pre-approval helps you know your budget and strengthens your position when making an offer on a property. We’ll guide you through the pre-approval process!.</p></div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


		</div>

	);
}

export default TesterPage