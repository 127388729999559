import React from 'react';


import "./forfingconsection.scss";
import "./forzurichsection.scss";
import "./__custom.css";

import './responsive.css'

const TesterPage = () => {
	return (
		<>
			<div className='zurich-section'>
				<div className="body_wrap">
					<div className="page_wrap" style={{minHeight:"unset"}}>

						<header
							className="top_panel top_panel_custom top_panel_custom_92 top_panel_custom_header-default&#9;&#9;&#9;&#9; without_bg_image scheme_dark">
							<div data-elementor-type="cpt_layouts" data-elementor-id="92" className="elementor elementor-92">
								<section
									className="elementor-section elementor-top-section elementor-element elementor-element-0ee19b5 elementor-section-full_width sc_layouts_row sc_layouts_row_type_compact elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
									style={{backgroundImage:`url(/images/contact-us/main-bg.png)`}} data-id="0ee19b5" data-element_type="section"
									data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
									<div className="elementor-background-overlay" />
									<div className="elementor-container elementor-column-gap-extended">
										<div
											className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d2ebbc2 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
											data-id="d2ebbc2" data-element_type="column">
											<div className="elementor-widget-wrap elementor-element-populated">
										
												<div
													className="sc_layouts_item elementor-element elementor-element-caf6eed sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
													data-id="caf6eed" data-element_type="widget" data-widget_type="spacer.default">
													<div className="elementor-widget-container">
														<div className="elementor-spacer">
															<div className="elementor-spacer-inner" />
														</div>
													</div>
												</div>
												<section
													className="elementor-section elementor-inner-section elementor-element elementor-element-d67700c elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
													data-id="d67700c" data-element_type="section">
													<div className="elementor-container elementor-column-gap-extended">
														<div
															className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-f56af19 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
															data-id="f56af19" data-element_type="column">
															<div className="elementor-widget-wrap elementor-element-populated">
																<div
																	className="sc_layouts_item elementor-element elementor-element-1970e32 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
																	data-id="1970e32" data-element_type="widget"
																	data-widget_type="trx_sc_layouts_title.default">
																	<div className="elementor-widget-container">
																		<div className="sc_layouts_title sc_align_center with_content without_image without_tint">
																			<div className="sc_layouts_title_content">
																				<div className="sc_layouts_title_title">
																					<h1 className="sc_layouts_title_caption">Contact Us</h1>
																				</div>
																				<div className="sc_layouts_title_breadcrumbs">
																					<div className="breadcrumbs"><span className="breadcrumbs_item current">
																							Reach Out to Us for Expert Help!</span>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>
												<div
													className="sc_layouts_item elementor-element elementor-element-7672996 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
													data-id="7672996" data-element_type="widget" data-widget_type="spacer.default">
													<div className="elementor-widget-container">
														<div className="elementor-spacer">
															<div className="elementor-spacer-inner" />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</section>
							</div>
						</header>
					</div>
				</div>
			</div>
			
			
			<div className='zurich-section'>
				<div className="body_wrap">
					<div className="page_wrap" style={{minHeight:"unset"}}>

			
							<div className="content_wrap_fullscreen">
								<div className="content">
									<a id="content_skip_link_anchor" className="zurich_skip_link_anchor" href="#"></a>
									<article id="post-217" className="post_item_single post_type_page post-217 page type-page status-publish hentry">
										<div className="post_content entry-content">
											<div data-elementor-type="wp-page" data-elementor-id="217" className="elementor elementor-217">
												<section className="elementor-section elementor-top-section elementor-element elementor-element-b12ae64 elementor-section-full_width elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="b12ae64" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
													<div className="elementor-container elementor-column-gap-no">
														<div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ee74945 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="ee74945" data-element_type="column">
															<div className="elementor-widget-wrap elementor-element-populated">
																
																<div className="elementor-element elementor-element-656b320 sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="656b320" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<section className="elementor-section elementor-inner-section elementor-element elementor-element-340fdbb elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="340fdbb" data-element_type="section">
																	<div className="elementor-container elementor-column-gap-extended">
																		<div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-34a5f2c sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="34a5f2c" data-element_type="column">
																			<div className="elementor-widget-wrap elementor-element-populated">
																				<div className="elementor-element elementor-element-292d3e5 sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="292d3e5" data-element_type="widget" data-widget_type="trx_sc_title.default">
																					<div className="elementor-widget-container">
																						<div className="sc_title sc_title_default">
																						
																							<h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag"><span className="sc_item_title_text">How can we  <br />help you?</span>
																							</h1>
																							<div className="sc_item_descr sc_title_descr">
																								<p>Please leave your details, and we’ll connect you with one of our expert financial advisers soon. </p>
																							</div>
																						</div>
																					</div>
																				</div>
																				<div className="elementor-element elementor-element-5df0148 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="5df0148" data-element_type="widget" data-widget_type="spacer.default">
																					<div className="elementor-widget-container">
																						<div className="elementor-spacer">
																							<div className="elementor-spacer-inner"></div>
																						</div>
																					</div>
																				</div>
																				<div className="elementor-element elementor-element-f953791 sc_fly_static elementor-widget elementor-widget-trx_sc_icons trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="f953791" data-element_type="widget" data-widget_type="trx_sc_icons.default">
																					<div className="elementor-widget-container">
																						<div className="sc_icons sc_icons_simple sc_icons_size_small sc_align_none">
																							<div className="sc_icons_item with_more">
																								<img src='/images/contact-us/location.svg' className="sc_icons_icon sc_icon_type_ icon-marker"></img>
																								<div className="sc_icons_item_details">
																									<div className="sc_icons_item_description">
																										<span>Suite V3, 4 Woodland Road, DL3 7PJ </span>
																									</div>
																								</div>
																							</div>
																							<div className="sc_icons_item sc_icons_item_linked with_more">
																								<img src='/images/contact-us/phone.svg' className="sc_icons_icon sc_icon_type_ icon-iphone"></img>

																								<div className="sc_icons_item_details"><a href="tel:18408412569" className="sc_icons_item_more_link"> <span className="link_text">02045424350</span><span className="link_icon"></span> </a><a href="tel:18408412569" className="sc_icons_item_link"> </a>
																								</div>
																							</div>
																							<div className="sc_icons_item with_more">
																								<img src='/images/contact-us/mail.svg' className="sc_icons_icon sc_icon_type_ icon-envelop-2"></img>

																								<div className="sc_icons_item_details">
																									<div className="sc_icons_item_description">
																										<span><a href="mailto:info@email.com" className="underline_hover">connect@reservefinancialservices.com </a>
																										</span>
																									</div>
																								</div>
																							</div>
																						</div>

																					</div>
																				</div>
																			</div>
																		</div>
																		<div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f86ad2b sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="f86ad2b" data-element_type="column">
																			<div className="elementor-widget-wrap elementor-element-populated">
																				<div className="elementor-element elementor-element-dcd675b sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="dcd675b" data-element_type="widget" data-widget_type="spacer.default">
																					<div className="elementor-widget-container">
																						<div className="elementor-spacer">
																							<div className="elementor-spacer-inner"></div>
																						</div>
																					</div>
																				</div>
																				<div className="elementor-element elementor-element-55ec12e sc_fly_static elementor-widget elementor-widget-trx_sc_contact_form_7 trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="55ec12e" data-element_type="widget" data-widget_type="trx_sc_contact_form_7.default">
																					<div className="elementor-widget-container">
																						<div className="wpcf7 js alert_inited" id="wpcf7-f547-p217-o1" lang="en-US" dir="ltr"> <div className="screen-reader-response">
																							<p role="status" aria-live="polite" aria-atomic="true"></p>
																							<ul></ul></div>
																							<form action="/" method="post" className="wpcf7-form init" aria-label="Contact form" novalidate="novalidate" data-status="init" data-inited-validation="1">
																								<div style={{ display: "none" }}>
																									<input type="hidden" name="_wpcf7" value="547" />
																									<input type="hidden" name="_wpcf7_version" value="5.9.5" />
																									<input type="hidden" name="_wpcf7_locale" value="en_US" />
																									<input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f547-p217-o1" />
																									<input type="hidden" name="_wpcf7_container_post" value="217" />
																									<input type="hidden" name="_wpcf7_posted_data_hash" value="" />
																								</div>
																								<div className="form-style-3">
																									<div className="columns_wrap">
																										<div className="column-1_2">
																											<p>
																												<span className="style-line icon-name">
																													<span className="wpcf7-form-control-wrap" data-name="your-name">
																														<input size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required fill_inited" aria-required="true" aria-invalid="false" placeholder="Full Name" value="" type="text" name="your-name" />
																														<span className="line">
																														</span>
																													</span>
																												</span>
																											</p>
																										</div>

																										<div className="column-1_2">
																											<p><span className="style-line icon-email"><span className="wpcf7-form-control-wrap" data-name="your-email"><input size="40" className="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email fill_inited" aria-required="true" aria-invalid="false" placeholder="Email Address" value="" type="email" name="your-email" />
																												<span className="line"></span></span></span>
																											</p> </div>
																									</div>
																									<div className="columns_wrap"> <div className="column-1_2">
																										<p><span className="style-line icon-phone"><span className="wpcf7-form-control-wrap" data-name="phone">
																											<input size="40" className="wpcf7-form-control wpcf7-tel wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-tel fill_inited" aria-required="true" aria-invalid="false" placeholder="Phone" value="" type="tel" name="phone" />
																											<span className="line"></span></span></span>
																										</p> </div> <div className="column-1_2">
																											<p><span className="style-line icon-subject"><span className="wpcf7-form-control-wrap" data-name="subject">
																												<input size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required fill_inited" aria-required="true" aria-invalid="false" placeholder="Service Type" value="" type="text" name="subject" />
																												<span className="line"></span></span></span>
																											</p> </div>
																									</div>
																									<div className="columns_wrap margin-bottom"> <div className="column-1_1">
																										<p><span className="style-line icon-textarea"><span className="wpcf7-form-control-wrap" data-name="your-message"><textarea cols="40" rows="10" className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required fill_inited" aria-required="true" aria-invalid="false" placeholder="Query Details " name="your-message"></textarea>
																											<span className="line"></span></span></span>
																										</p> </div>
																									</div>
																									<p><span className="wpcf7-submit-style">
																										<input className="wpcf7-form-control wpcf7-submit has-spinner" type="submit" value="Get an Advisor" />
																										<span className="submit-style-in"></span></span><span className="wpcf7-spinner"></span><span className="wpcf7-form-control-wrap" data-name="acceptance"><span className="wpcf7-form-control wpcf7-acceptance"><span className="wpcf7-list-item">
																											<label>
																												<input type="checkbox" name="acceptance" value="1" aria-invalid="false" className="inited" />
																												<span className="wpcf7-list-item-label">I agree that my data is <a data-savepage-href="/privacy-policy/" href="#">collected</a>.</span>
																											</label></span></span></span>
																									</p>
																								</div><div className="wpcf7-response-output" aria-hidden="true"></div>
																							</form>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</section> <div className="elementor-element elementor-element-f4172cf sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="f4172cf" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div> </div>
																<div className="elementor-element elementor-element-5a22d5f sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="5a22d5f" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>





											</div>
										</div>
									</article>


								</div>
							</div>
						

					</div>
				</div >
			</div>
		
			<div className='fingcon-section'>
				<div data-elementor-type="wp-post" data-elementor-id="2305" className="elementor elementor-2305">
					{/* <div className="elementor-element elementor-element-78d561c e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="78d561c" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
					<div className="e-con-inner">
						<div className="elementor-element elementor-element-f90cd96 elementor-widget__width-auto elementor-widget elementor-widget-qi_addons_for_elementor_highlight" data-id="f90cd96" data-element_type="widget" data-widget_type="qi_addons_for_elementor_highlight.default">
							<div className="elementor-widget-container">
								<p className="qodef-shortcode qodef-m  qodef-qi-highlight  qodef-highlight-style--color">
									<span className="qodef-highlight-text" style={{ backgroundImage: `linear-gradient(to bottom, transparent 0%, #00000000 0%, #00000000 100%, transparent 100%)` }}>Contact Us</span></p>
							</div>
						</div>
						<div className="elementor-element elementor-element-ad0ed45 elementor-widget__width-initial elementor-widget-tablet__width-initial elementor-widget elementor-widget-qi_addons_for_elementor_animated_text" data-id="ad0ed45" data-element_type="widget" data-widget_type="qi_addons_for_elementor_animated_text.default">
							<div className="elementor-widget-container">
								<div className="qodef-shortcode qodef-m qodef-qi-animated-text qodef--animated-by-word qodef--alignment-center qodef-qi--has-appear qodef--appear-from-bottom qodef-qi--appeared" data-appear-delay="100">
									<h1 className="qodef-m-title">
										<span className="qodef-e-word">Reach</span> <span className="qodef-e-word">Out</span> <span className="qodef-e-word">to</span> <span className="qodef-e-word">Us</span> <span className="qodef-e-word">for</span> <span className="qodef-e-word">Expert</span> <span className="qodef-e-word">Help!</span> <span className="qodef-e-word"></span>	</h1>
								</div>
							</div>
						</div>
						<div className="elementor-element elementor-element-10da4c8 elementor-widget__width-auto elementor-widget elementor-widget-qi_addons_for_elementor_interactive_link_showcase" data-id="10da4c8" data-element_type="widget" data-widget_type="qi_addons_for_elementor_interactive_link_showcase.default">
							<div className="elementor-widget-container">
								<div className="qodef-shortcode qodef-m qodef-qi-interactive-link-showcase qodef-layout--inline qodef-hover--underline qodef--init">
									<div className="qodef-m-items">
										<a itemprop="url" className="qodef-m-item qodef-e qodef--active" data-savepage-href="#" href="https://elementor.thememarch.com/fingcon/template-kit/contact-us/#" target="_self">
											<span className="qodef-e-title"><span className="qodef-e-inner-title">Home </span></span>
										</a>
										<a itemprop="url" className="qodef-m-item qodef-e" data-savepage-href="#" href="https://elementor.thememarch.com/fingcon/template-kit/contact-us/#" target="_self">
											<span className="qodef-e-title"><span className="qodef-e-inner-title">/</span></span>
										</a>
										<a itemprop="url" className="qodef-m-item qodef-e" data-savepage-href="#" href="https://elementor.thememarch.com/fingcon/template-kit/contact-us/#" target="_self">
											<span className="qodef-e-title"><span className="qodef-e-inner-title">Contact Us</span></span>
										</a>
									</div>
									<div className="qodef-m-images">
										<div className="qodef-e-image qodef-position--left elementor-repeater-item-9404180 qodef--active">
										</div>
										<div className="qodef-e-image qodef-position--left elementor-repeater-item-b9c8f83">
										</div>
										<div className="qodef-e-image qodef-position--left elementor-repeater-item-b12ca9c">
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="elementor-element elementor-element-7ab6878 elementor-absolute e-transform e-transform elementor-hidden-mobile elementor-widget elementor-widget-heading" data-id="7ab6878" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_transform_rotateZ_effect&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:-90,&quot;sizes&quot;:[]},&quot;_transform_translateX_effect&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:175,&quot;sizes&quot;:[]},&quot;_transform_translateY_effect&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:45,&quot;sizes&quot;:[]},&quot;_transform_rotateZ_effect_laptop&quot;:{&quot;unit&quot;:&quot;deg&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_rotateZ_effect_tablet&quot;:{&quot;unit&quot;:&quot;deg&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_rotateZ_effect_mobile&quot;:{&quot;unit&quot;:&quot;deg&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateX_effect_laptop&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateX_effect_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateX_effect_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateY_effect_laptop&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateY_effect_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;_transform_translateY_effect_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}" data-widget_type="heading.default">
							<div className="elementor-widget-container">
								<link rel="stylesheet" href="css/style28.css" /><h6 className="elementor-heading-title elementor-size-default">(406) 555-0120</h6>		</div>
						</div>
					</div>
				</div> */}
					<div className="elementor-element elementor-element-f8da56c e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="f8da56c" data-element_type="container">
						<div className="e-con-inner">
							<div className="elementor-element elementor-element-f1c2c9d elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="f1c2c9d" data-element_type="widget" data-widget_type="divider.default">
								<div className="elementor-widget-container">
									<link rel="stylesheet" href="css/style29.css" />		<div className="elementor-divider">
										<span className="elementor-divider-separator">
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="elementor-element elementor-element-ddd9b17 e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="ddd9b17" data-element_type="container">
						<div className="e-con-inner">
							<div className="elementor-element elementor-element-4b2958b e-con-full e-flex e-con e-child" data-id="4b2958b" data-element_type="container">
								<div className="elementor-element elementor-element-4319bfa elementor-widget__width-auto animated-fast elementor-view-default elementor-widget elementor-widget-icon animated fadeIn" data-id="4319bfa" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:0}" data-widget_type="icon.default">
									<div className="elementor-widget-container">
										<div className="elementor-icon-wrapper">
											<img src='/images/contact-us/phone.svg'></img>
										</div>
									</div>
								</div>
								<div className="elementor-element elementor-element-fc12afe elementor-widget elementor-widget-heading" data-id="fc12afe" data-element_type="widget" data-widget_type="heading.default">
									<div className="elementor-widget-container">
										<h5 className="elementor-heading-title elementor-size-default">02045424350 </h5>		</div>
								</div>
								<div className="elementor-element elementor-element-633275b elementor-widget elementor-widget-heading" data-id="633275b" data-element_type="widget" data-widget_type="heading.default">
									<div className="elementor-widget-container">
										<h5 className="elementor-heading-title elementor-size-default">02033769868 </h5>		</div>
								</div>
								<div className="elementor-element elementor-element-64269bf elementor-widget elementor-widget-text-editor" data-id="64269bf" data-element_type="widget" data-widget_type="text-editor.default">
									<div className="elementor-widget-container">
										<link rel="stylesheet" href="css/style30.css" />				<p>We’re always here to help—reach out to us for any questions, support, or assistance you may need. </p>						</div>
								</div>
								<div className="elementor-element elementor-element-432025c elementor-widget elementor-widget-qi_addons_for_elementor_button" data-id="432025c" data-element_type="widget" data-widget_type="qi_addons_for_elementor_button.default">
									<div className="elementor-widget-container">
										<a className="qodef-shortcode qodef-m  qodef-qi-button qodef-html--link qodef-layout--outlined qodef-type--icon-boxed qodef-size--full qodef-hover--reveal qodef--reveal-horizontal qodef-icon--left qodef-hover--icon-move-horizontal     " data-savepage-href="#" href="https://elementor.thememarch.com/fingcon/template-kit/contact-us/#" target="_self">
											<span className="qodef-m-text">Talk to an Advisor </span>
											<span className="qodef-m-icon " style={{ width: "24px" }}>
												<span className="qodef-m-icon-inner">
													<svg className="e-font-icon-svg e-fas-phone-alt" width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M7.85715 3.5767L7.36006 2.4582C7.03502 1.72689 6.8725 1.36121 6.62944 1.08137C6.32484 0.730665 5.9278 0.472641 5.48361 0.336728C5.12917 0.228272 4.72903 0.228271 3.92872 0.228271C2.75798 0.228271 2.1726 0.228272 1.68122 0.45332C1.10238 0.718419 0.579617 1.29405 0.371346 1.89567C0.194531 2.4064 0.245173 2.93126 0.346469 3.98098C1.42465 15.1544 7.55044 21.2802 18.7239 22.3583C19.7736 22.4597 20.2984 22.5103 20.8092 22.3335C21.4108 22.1253 21.9864 21.6024 22.2515 21.0237C22.4766 20.5322 22.4766 19.9469 22.4766 18.7761C22.4766 17.9758 22.4766 17.5756 22.3682 17.2212C22.2322 16.777 21.9742 16.38 21.6235 16.0754C21.3436 15.8323 20.9779 15.6698 20.2466 15.3448L19.1282 14.8476C18.3361 14.4956 17.9402 14.3197 17.5377 14.2814C17.1526 14.2448 16.7644 14.2989 16.4039 14.4392C16.0272 14.5859 15.6942 14.8633 15.0284 15.4181C14.3656 15.9705 14.0342 16.2466 13.6293 16.3945C13.2704 16.5256 12.7958 16.5743 12.4177 16.5185C11.9913 16.4556 11.6646 16.2812 11.0115 15.9321C8.97931 14.8461 7.85873 13.7255 6.77273 11.6933C6.42369 11.0402 6.24917 10.7136 6.18632 10.2871C6.1306 9.90899 6.17917 9.43442 6.31031 9.07553C6.45823 8.67057 6.73439 8.33919 7.28671 7.6764C7.84156 7.01058 8.11899 6.67766 8.26567 6.30099C8.40604 5.94053 8.46007 5.55221 8.42344 5.16712C8.38517 4.76471 8.20917 4.36871 7.85715 3.5767Z" fill="#050C9B" />
													</svg>
													<svg className="e-font-icon-svg e-fas-phone-alt" width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M7.85715 3.5767L7.36006 2.4582C7.03502 1.72689 6.8725 1.36121 6.62944 1.08137C6.32484 0.730665 5.9278 0.472641 5.48361 0.336728C5.12917 0.228272 4.72903 0.228271 3.92872 0.228271C2.75798 0.228271 2.1726 0.228272 1.68122 0.45332C1.10238 0.718419 0.579617 1.29405 0.371346 1.89567C0.194531 2.4064 0.245173 2.93126 0.346469 3.98098C1.42465 15.1544 7.55044 21.2802 18.7239 22.3583C19.7736 22.4597 20.2984 22.5103 20.8092 22.3335C21.4108 22.1253 21.9864 21.6024 22.2515 21.0237C22.4766 20.5322 22.4766 19.9469 22.4766 18.7761C22.4766 17.9758 22.4766 17.5756 22.3682 17.2212C22.2322 16.777 21.9742 16.38 21.6235 16.0754C21.3436 15.8323 20.9779 15.6698 20.2466 15.3448L19.1282 14.8476C18.3361 14.4956 17.9402 14.3197 17.5377 14.2814C17.1526 14.2448 16.7644 14.2989 16.4039 14.4392C16.0272 14.5859 15.6942 14.8633 15.0284 15.4181C14.3656 15.9705 14.0342 16.2466 13.6293 16.3945C13.2704 16.5256 12.7958 16.5743 12.4177 16.5185C11.9913 16.4556 11.6646 16.2812 11.0115 15.9321C8.97931 14.8461 7.85873 13.7255 6.77273 11.6933C6.42369 11.0402 6.24917 10.7136 6.18632 10.2871C6.1306 9.90899 6.17917 9.43442 6.31031 9.07553C6.45823 8.67057 6.73439 8.33919 7.28671 7.6764C7.84156 7.01058 8.11899 6.67766 8.26567 6.30099C8.40604 5.94053 8.46007 5.55221 8.42344 5.16712C8.38517 4.76471 8.20917 4.36871 7.85715 3.5767Z" fill="#050C9B" />
													</svg>
												</span>
											</span>
										</a>
									</div>
								</div>
							</div>
							<div className="elementor-element elementor-element-dc1e3ef e-con-full elementor-hidden-mobile e-flex e-con e-child" data-id="dc1e3ef" data-element_type="container">
								<div className="elementor-element elementor-element-371ca45 elementor-widget__width-auto elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="371ca45" data-element_type="widget" data-widget_type="divider.default">
									<div className="elementor-widget-container">
										<div className="elementor-divider">
											<span className="elementor-divider-separator">
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className="elementor-element elementor-element-bf1bd2a e-con-full e-flex e-con e-child" data-id="bf1bd2a" data-element_type="container">
								<div className="elementor-element elementor-element-cff3e88 elementor-widget__width-auto animated-fast elementor-view-default elementor-widget elementor-widget-icon animated fadeIn" data-id="cff3e88" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:0}" data-widget_type="icon.default">
									<div className="elementor-widget-container">
										<div className="elementor-icon-wrapper">
											<img src='/images/contact-us/mail.svg'></img>
										</div>
									</div>
								</div>
								<div className="elementor-element elementor-element-225380a elementor-widget elementor-widget-heading" data-id="225380a" data-element_type="widget" data-widget_type="heading.default">
									<div className="elementor-widget-container">
										<h5 className="elementor-heading-title elementor-size-default">connect@reservefinancialservices.com </h5>		</div>
								</div>
								<div className="elementor-element elementor-element-9315caf elementor-widget elementor-widget-heading" data-id="9315caf" data-element_type="widget" data-widget_type="heading.default">
									<div className="elementor-widget-container">
										<h5 className="elementor-heading-title elementor-size-default">connect@reservefinancialservices.com </h5>		</div>
								</div>
								<div className="elementor-element elementor-element-ee710ab elementor-widget elementor-widget-text-editor" data-id="ee710ab" data-element_type="widget" data-widget_type="text-editor.default">
									<div className="elementor-widget-container">
										<p>Feel free to contact us via email for any questions or support. We’re always happy to assist you. </p>						</div>
								</div>
								<div className="elementor-element elementor-element-3206301 elementor-widget elementor-widget-qi_addons_for_elementor_button" data-id="3206301" data-element_type="widget" data-widget_type="qi_addons_for_elementor_button.default">
									<div className="elementor-widget-container">
										<a className="qodef-shortcode qodef-m  qodef-qi-button qodef-html--link qodef-layout--outlined qodef-type--icon-boxed qodef-size--full qodef-hover--reveal qodef--reveal-horizontal qodef-icon--left qodef-hover--icon-move-horizontal     " data-savepage-href="#" href="https://elementor.thememarch.com/fingcon/template-kit/contact-us/#" target="_self">
											<span className="qodef-m-text">Email Us</span>
											<span className="qodef-m-icon " style={{ width: "24px" }}>
												<span className="qodef-m-icon-inner">
													<svg className='e-font-icon-svg e-fas-envelope' width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M0.825644 12.1175C0.904418 15.8116 0.943811 17.6586 2.30687 19.0269C3.66992 20.3951 5.56695 20.4427 9.36103 20.538C11.6994 20.5968 14.0147 20.5968 16.3531 20.538C20.1472 20.4427 22.0442 20.3951 23.4073 19.0269C24.7703 17.6586 24.8097 15.8116 24.8884 12.1175C24.9139 10.9296 24.9139 9.74894 24.8884 8.56113C24.8097 4.86703 24.7703 3.01998 23.4073 1.65174C22.0442 0.283496 20.1472 0.235836 16.3531 0.140505C14.0147 0.0817476 11.6994 0.0817476 9.36101 0.140493C5.56695 0.235812 3.66992 0.283471 2.30686 1.65172C0.943799 3.01996 0.904418 4.86702 0.825632 8.56113C0.800302 9.74894 0.800314 10.9296 0.825644 12.1175Z" fill="#050C9B" />
														<path d="M0.806641 3.10907L9.13712 7.82918C12.2083 9.5693 13.5059 9.5693 16.577 7.82918L24.9075 3.10907" stroke="white" stroke-width="3" stroke-linejoin="round" />
													</svg>
													<svg className='e-font-icon-svg e-fas-envelope' width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M0.825644 12.1175C0.904418 15.8116 0.943811 17.6586 2.30687 19.0269C3.66992 20.3951 5.56695 20.4427 9.36103 20.538C11.6994 20.5968 14.0147 20.5968 16.3531 20.538C20.1472 20.4427 22.0442 20.3951 23.4073 19.0269C24.7703 17.6586 24.8097 15.8116 24.8884 12.1175C24.9139 10.9296 24.9139 9.74894 24.8884 8.56113C24.8097 4.86703 24.7703 3.01998 23.4073 1.65174C22.0442 0.283496 20.1472 0.235836 16.3531 0.140505C14.0147 0.0817476 11.6994 0.0817476 9.36101 0.140493C5.56695 0.235812 3.66992 0.283471 2.30686 1.65172C0.943799 3.01996 0.904418 4.86702 0.825632 8.56113C0.800302 9.74894 0.800314 10.9296 0.825644 12.1175Z" fill="#050C9B" />
														<path d="M0.806641 3.10907L9.13712 7.82918C12.2083 9.5693 13.5059 9.5693 16.577 7.82918L24.9075 3.10907" stroke="white" stroke-width="3" stroke-linejoin="round" />
													</svg>
												</span>
											</span>
										</a>
									</div>
								</div>
							</div>
							<div className="elementor-element elementor-element-02cd146 e-con-full elementor-hidden-mobile e-flex e-con e-child" data-id="02cd146" data-element_type="container">
								<div className="elementor-element elementor-element-6ae9996 elementor-widget__width-auto elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="6ae9996" data-element_type="widget" data-widget_type="divider.default">
									<div className="elementor-widget-container">
										<div className="elementor-divider">
											<span className="elementor-divider-separator">
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className="elementor-element elementor-element-9f30efe e-con-full e-flex e-con e-child" data-id="9f30efe" data-element_type="container">
								<div className="elementor-element elementor-element-69c4973 elementor-widget__width-auto animated-fast elementor-view-default elementor-widget elementor-widget-icon animated fadeIn" data-id="69c4973" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:0}" data-widget_type="icon.default">
									<div className="elementor-widget-container">
										<div className="elementor-icon-wrapper">
											<img src='/images/contact-us/location.svg'></img>
										</div>
									</div>
								</div>
								<div className="elementor-element elementor-element-5b13f15 elementor-widget elementor-widget-heading" data-id="5b13f15" data-element_type="widget" data-widget_type="heading.default">
									<div className="elementor-widget-container">
										<h5 className="elementor-heading-title elementor-size-default">Suite V3, 4 Woodland Road, DL3 7PJ </h5>		</div>
								</div>
								<div className="elementor-element elementor-element-7acf282 elementor-widget elementor-widget-text-editor" data-id="7acf282" data-element_type="widget" data-widget_type="text-editor.default">
									<div className="elementor-widget-container">
										<p><strong>Mon – Fri: 9AM – 5PM,</strong></p>						</div>
								</div>
								<div className="elementor-element elementor-element-0770a44 elementor-widget elementor-widget-qi_addons_for_elementor_button" data-id="0770a44" data-element_type="widget" data-widget_type="qi_addons_for_elementor_button.default">
									<div className="elementor-widget-container">
										<a className="qodef-shortcode qodef-m  qodef-qi-button qodef-html--link qodef-layout--outlined qodef-type--icon-boxed qodef-size--full qodef-hover--reveal qodef--reveal-horizontal qodef-icon--left qodef-hover--icon-move-horizontal     " data-savepage-href="#" href="https://elementor.thememarch.com/fingcon/template-kit/contact-us/#" target="_self">
											<span className="qodef-m-text">Get Direction </span>
											<span className="qodef-m-icon " style={{ width: "24px" }}>
												<span className="qodef-m-icon-inner">
													<svg class="e-font-icon-svg e-fas-map-marker-alt" width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M13.3321 25.058C12.8046 25.5659 12.0997 25.8499 11.3661 25.8499C10.6323 25.8499 9.92734 25.5659 9.39986 25.058C4.56982 20.3777 -1.90304 15.1494 1.25358 7.55891C2.96032 3.45478 7.05731 0.828613 11.3661 0.828613C15.6747 0.828613 19.7716 3.4548 21.4784 7.55891C24.6311 15.1398 18.174 20.3938 13.3321 25.058Z" fill="#050C9B" />
														<path d="M15.2752 11.7754C15.2752 13.9346 13.5248 15.685 11.3656 15.685C9.20645 15.685 7.45605 13.9346 7.45605 11.7754C7.45605 9.61625 9.20645 7.86588 11.3656 7.86588C13.5248 7.86588 15.2752 9.61625 15.2752 11.7754Z" fill="#D9D9D9" />
													</svg>
													<svg class="e-font-icon-svg e-fas-map-marker-alt" width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M13.3321 25.058C12.8046 25.5659 12.0997 25.8499 11.3661 25.8499C10.6323 25.8499 9.92734 25.5659 9.39986 25.058C4.56982 20.3777 -1.90304 15.1494 1.25358 7.55891C2.96032 3.45478 7.05731 0.828613 11.3661 0.828613C15.6747 0.828613 19.7716 3.4548 21.4784 7.55891C24.6311 15.1398 18.174 20.3938 13.3321 25.058Z" fill="#050C9B" />
														<path d="M15.2752 11.7754C15.2752 13.9346 13.5248 15.685 11.3656 15.685C9.20645 15.685 7.45605 13.9346 7.45605 11.7754C7.45605 9.61625 9.20645 7.86588 11.3656 7.86588C13.5248 7.86588 15.2752 9.61625 15.2752 11.7754Z" fill="#D9D9D9" />
													</svg>
												</span>
											</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
		

					<div className="elementor-element elementor-element-f8da56c e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="f8da56c" data-element_type="container">
						<div className="e-con-inner">
							<div className="elementor-element elementor-element-f1c2c9d elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="f1c2c9d" data-element_type="widget" data-widget_type="divider.default">
								<div className="elementor-widget-container">
									<link rel="stylesheet" href="css/style29.css" />		<div className="elementor-divider">
										<span className="elementor-divider-separator">
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>




				</div>
			</div>


		</>

	);
}

export default TesterPage