// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-content{
    background-color: #FFF !important;
   width: 100vw;
   height: fit-content;
   scroll-behavior: smooth;
}


`, "",{"version":3,"sources":["webpack://./src/user-panel/common-component/Layout.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;GAClC,YAAY;GACZ,mBAAmB;GACnB,uBAAuB;AAC1B","sourcesContent":[".app-content{\r\n    background-color: #FFF !important;\r\n   width: 100vw;\r\n   height: fit-content;\r\n   scroll-behavior: smooth;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
