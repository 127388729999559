// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.document-approve-modal .card {
  width: fit-content !important;
}
.document-approve-modal .card table tr td,
.document-approve-modal .card table tr th {
  padding: 20px 10px;
}
.document-approve-modal .card table tr td .doc-view-btn {
  display: block;
  height: 30px;
  width: 80px;
  background-color: rgb(216, 154, 37);
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  border-radius: 8px;
  text-align: center;
}
.document-approve-modal .card table tr td .approve-btn {
  display: block;
  height: 30px;
  width: 80px;
  background-color: rgb(62, 135, 38);
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  border-radius: 8px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/common-components/ApproveDocumentsPopup/ApproveDocumentsPopup.scss"],"names":[],"mappings":"AACI;EACI,6BAAA;AAAR;AAKgB;;EAEI,kBAAA;AAHpB;AAOoB;EACI,cAAA;EACA,YAAA;EACA,WAAA;EACA,mCAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;AALxB;AAQoB;EACI,cAAA;EACA,YAAA;EACA,WAAA;EACA,kCAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;AANxB","sourcesContent":[".document-approve-modal {\r\n    .card {\r\n        width: fit-content !important;\r\n\r\n        table {\r\n            tr {\r\n\r\n                td,\r\n                th {\r\n                    padding: 20px 10px;\r\n                }\r\n\r\n                td {\r\n                    .doc-view-btn {\r\n                        display: block;\r\n                        height: 30px;\r\n                        width: 80px;\r\n                        background-color: rgb(216, 154, 37);\r\n                        color: #fff;\r\n                        font-weight: 600;\r\n                        font-size: 12px;\r\n                        border-radius: 8px;\r\n                        text-align:center;\r\n                    }\r\n\r\n                    .approve-btn {\r\n                        display: block;\r\n                        height: 30px;\r\n                        width: 80px;\r\n                        background-color: rgb(62, 135, 38);\r\n                        color: #fff;\r\n                        font-weight: 600;\r\n                        font-size: 12px;\r\n                        border-radius: 8px;\r\n                        text-align:center;\r\n\r\n                    }\r\n                }\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
