import React, { useEffect, useContext } from 'react'
import $ from 'jquery';

import './AboutUs.scss'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../../authentication/pages/UserContext';

const Aboutus = () => {
  const navigate = useNavigate()
  const { isLoggedIn, login, logout } = useContext(UserContext);

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])

  return (
    <div className='about-us-page'>

    </div>
  )

}

export default Aboutus