import React, { useState } from 'react';

import "./forzurichsection.scss";
import "./forfingconsection.scss";
import "./forzurichaboutsection.scss";

const TesterPage = () => {
	const[tabSelected,selectTab]=useState(null)
	return (
<div className='services-page'>
			<div className="body_wrap">
				<div className="page_wrap">

					<header
						className="top_panel top_panel_custom top_panel_custom_92 top_panel_custom_header-default&#9;&#9;&#9;&#9; without_bg_image scheme_dark">
						<div data-elementor-type="cpt_layouts" data-elementor-id="92" className="elementor elementor-92">
							<section
								className="elementor-section elementor-top-section elementor-element elementor-element-0ee19b5 elementor-section-full_width sc_layouts_row sc_layouts_row_type_compact elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
								style={{ backgroundImage: `url(/images/services/main-bg.png)` }} data-id="0ee19b5" data-element_type="section"
								data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
								<div className="elementor-background-overlay" />
								<div className="elementor-container elementor-column-gap-extended">
									<div
										className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d2ebbc2 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
										data-id="d2ebbc2" data-element_type="column">
										<div className="elementor-widget-wrap elementor-element-populated">

											<div
												className="sc_layouts_item elementor-element elementor-element-caf6eed sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
												data-id="caf6eed" data-element_type="widget" data-widget_type="spacer.default">
												<div className="elementor-widget-container">
													<div className="elementor-spacer">
														<div className="elementor-spacer-inner" />
													</div>
												</div>
											</div>
											<section
												className="elementor-section elementor-inner-section elementor-element elementor-element-d67700c elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
												data-id="d67700c" data-element_type="section">
												<div className="elementor-container elementor-column-gap-extended">
													<div
														className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-f56af19 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
														data-id="f56af19" data-element_type="column">
														<div className="elementor-widget-wrap elementor-element-populated">
															<div
																className="sc_layouts_item elementor-element elementor-element-1970e32 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
																data-id="1970e32" data-element_type="widget"
																data-widget_type="trx_sc_layouts_title.default">
																<div className="elementor-widget-container">
																	<div className="sc_layouts_title sc_align_center with_content without_image without_tint">
																		<div className="sc_layouts_title_content">
																			<div className="sc_layouts_title_title">
																				<h1 className="sc_layouts_title_caption">Services</h1>
																			</div>
																			<div className="sc_layouts_title_breadcrumbs">
																				<div className="breadcrumbs"><span className="breadcrumbs_item current">
																					We offer personalised strategies for your financial growth.</span>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</section>
											<div
												className="sc_layouts_item elementor-element elementor-element-7672996 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
												data-id="7672996" data-element_type="widget" data-widget_type="spacer.default">
												<div className="elementor-widget-container">
													<div className="elementor-spacer">
														<div className="elementor-spacer-inner" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
					</header>

					<div className="page_content_wrap">
						<div className="content_wrap_fullscreen">
							<div className="content"> <a id="content_skip_link_anchor" className="zurich_skip_link_anchor" href="#" />
								<article id="post-5059"
									className="post_item_single post_type_page post-5059 page type-page status-publish hentry">
									<div className="post_content entry-content">
										<div data-elementor-type="wp-page" data-elementor-id="5059" className="elementor elementor-5059">



											<section
												className="elementor-section elementor-top-section elementor-element elementor-element-7c739c8 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
												data-id="7c739c8" data-element_type="section">
												<div className="elementor-container elementor-column-gap-extended">
													<div
														className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6c0d36e sc_layouts_column_align_center sc_layouts_column sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
														data-id="6c0d36e" data-element_type="column">
														<div className="elementor-widget-wrap elementor-element-populated">
															<div data-animation-type="block"
																className="elementor-element elementor-element-80434b2 elementor-widget__width-initial animation_type_block sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited animated zurich-fadein"
																data-id="80434b2" data-element_type="widget"
																data-settings="{&quot;_animation&quot;:&quot;zurich-fadein&quot;,&quot;_animation_delay&quot;:200}"
																data-widget_type="trx_sc_title.default">
																<div className="elementor-widget-container">
																	<div className="sc_title sc_title_default">

																		<h1
																			className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag sc_typed">
																			<span className="sc_item_title_text">Expert Services That Support Your Financial Goals

																			</span>
																		</h1>
																	</div>
																</div>
															</div>
															<div
																className="elementor-element elementor-element-1f53a1e sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
																data-id="1f53a1e" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner" />
																	</div>
																</div>
															</div>

														</div>
													</div>
												</div>
											</section>
											<section
												className="elementor-section elementor-top-section elementor-element elementor-element-141ecbf elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
												data-id="141ecbf" data-element_type="section">
												<div className="elementor-container elementor-column-gap-extended">
													<div
														className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fb760ee sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
														data-id="fb760ee" data-element_type="column">
														<div className="elementor-widget-wrap elementor-element-populated">
															<div
																className="elementor-element elementor-element-2c3be78 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
																data-id="2c3be78" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner" />
																	</div>
																</div>
															</div>
															<div
																className="elementor-element elementor-element-65770d6 sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_layouts_hide_on_notebook sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
																data-id="65770d6" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner" />
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</section>
											<section
												className="elementor-section elementor-top-section elementor-element elementor-element-1345c3e elementor-section-full_width elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
												data-id="1345c3e" data-element_type="section">
												<div className="elementor-container elementor-column-gap-no">
													<div
														className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c261bb0 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
														data-id="c261bb0" data-element_type="column">
														<div className="elementor-widget-wrap elementor-element-populated">
															<div
																className="elementor-element elementor-element-9e54fd2 sc_fly_static elementor-widget elementor-widget-trx_sc_services trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
																data-id="9e54fd2" data-element_type="widget" data-widget_type="trx_sc_services.default">
																<div className="elementor-widget-container">
																	<div className="sc_services sc_services_qw-stylish sc_services_featured_top">
																		<div
																			className="sc_services_columns_wrap sc_item_columns sc_item_posts_container sc_item_columns_4 trx_addons_columns_wrap columns_padding_bottom columns_in_single_row">
																			<div className="trx_addons_column-1_4 trx_addons_column-1_2-tablet ">
																				<div data-post-id="4935"
																					className="sc_services_item sc_item_container post_container with_more with_content with_image sc_services_item_featured_none post-4935 cpt_services type-cpt_services status-publish has-post-thumbnail hentry cpt_services_group-what-we-do">
																					<div className="sc_services_item_content " style={{ backgroundImage: `url("/images/services/slider-sec-1.jpg")` }}>
																						<div className="sc_services_item_content_inner">
																							<div className="sc_services_item_content_inner_bottom">
																								<div className="sc_services_item_info_wrap">
																									<div className="sc_services_item_title_wrap">

																										<h4 className="sc_services_item_title">
																											<a
																												href="#/">Investing & Saving </a>
																										</h4>
																									</div>
																									<div className="sc_services_item_button sc_item_button"> <a
																										href="#"
																										className="sc_services_item_more_link"> <span className="link_text">Read
																											more</span><span className="link_icon" /> </a>
																									</div>
																								</div>
																								<div className="sc_services_item_text">
																									<p>Grow wealth steadily through smart investments. </p>
																								</div>
																							</div>
																						</div><a className="sc_services_item_link"
																							href="#" />
																					</div>
																				</div>
																			</div>
																			<div className="trx_addons_column-1_4 trx_addons_column-1_2-tablet ">
																				<div data-post-id="4936"
																					className="sc_services_item sc_item_container post_container with_more with_content with_image sc_services_item_featured_none post-4936 cpt_services type-cpt_services status-publish has-post-thumbnail hentry cpt_services_group-what-we-do">
																					<div className="sc_services_item_content "
																						style={{ backgroundImage: `url("/images/services/slider-sec-2.jpg")` }}>
																						<div className="sc_services_item_content_inner">
																							<div className="sc_services_item_content_inner_bottom">
																								<div className="sc_services_item_info_wrap">
																									<div className="sc_services_item_title_wrap">

																										<h4 className="sc_services_item_title">
																											<a href="/mortgage-services">Mortgage Services </a>
																										</h4>
																									</div>
																									<div className="sc_services_item_button sc_item_button"> <a
																										href="/mortgage-services"
																										className="sc_services_item_more_link"> <span className="link_text">Read
																											more</span><span className="link_icon" /> </a>
																									</div>
																								</div>
																								<div className="sc_services_item_text">
																									<p>Find the right mortgage that fits your dream home plans. </p>
																								</div>
																							</div>
																						</div><a className="sc_services_item_link"
																							href="#" />
																					</div>
																				</div>
																			</div>
																			<div className="trx_addons_column-1_4 trx_addons_column-1_2-tablet ">
																				<div data-post-id="4934"
																					className="sc_services_item sc_item_container post_container with_more with_content with_image sc_services_item_featured_none post-4934 cpt_services type-cpt_services status-publish has-post-thumbnail hentry cpt_services_group-what-we-do">
																					<div className="sc_services_item_content "
																						style={{ backgroundImage: `url("/images/services/slider-sec-3.jpg")` }}>
																						<div className="sc_services_item_content_inner">
																							<div className="sc_services_item_content_inner_bottom">
																								<div className="sc_services_item_info_wrap">
																									<div className="sc_services_item_title_wrap">

																										<h4 className="sc_services_item_title">
																											<a href="/home/services/agency-evolution/">Pension & Retirement </a>
																										</h4>
																									</div>
																									<div className="sc_services_item_button sc_item_button"> <a
																										href="#"
																										className="sc_services_item_more_link"> <span className="link_text">Read
																											more</span><span className="link_icon" /> </a>
																									</div>
																								</div>
																								<div className="sc_services_item_text">
																									<p>Secure your financial future with retirement planning. </p>
																								</div>
																							</div>
																						</div><a className="sc_services_item_link"
																							href="#" />
																					</div>
																				</div>
																			</div>
																			<div className="trx_addons_column-1_4 trx_addons_column-1_2-tablet ">
																				<div data-post-id="4080"
																					className="sc_services_item sc_item_container post_container with_more with_content with_image sc_services_item_featured_none post-4080 cpt_services type-cpt_services status-publish has-post-thumbnail hentry cpt_services_group-what-we-do">
																					<div className="sc_services_item_content" style={{ backgroundImage: `url("/images/services/slider-sec-4.jpg")` }}>
																						<div className="sc_services_item_content_inner">
																							<div className="sc_services_item_content_inner_bottom">
																								<div className="sc_services_item_info_wrap">
																									<div className="sc_services_item_title_wrap">

																										<h4 className="sc_services_item_title">
																											<a href="/home/services/maximizing-returns/">Insurance Services </a>
																										</h4>
																									</div>
																									<div className="sc_services_item_button sc_item_button"> <a
																										href="#"
																										className="sc_services_item_more_link"> <span className="link_text">Read
																											more</span><span className="link_icon" /> </a>
																									</div>
																								</div>
																								<div className="sc_services_item_text">
																									<p>Protect your family with comprehensive insurance. </p>
																								</div>
																							</div>
																						</div><a className="sc_services_item_link"
																							href="#" />
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</section>
											<section
												className="elementor-section elementor-top-section elementor-element elementor-element-1345c3e elementor-section-full_width elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
												data-id="1345c3e" data-element_type="section">
												<div className="elementor-container elementor-column-gap-no">
													<div
														className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c261bb0 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
														data-id="c261bb0" data-element_type="column">
														<div className="elementor-widget-wrap elementor-element-populated">
															<div
																className="elementor-element elementor-element-9e54fd2 sc_fly_static elementor-widget elementor-widget-trx_sc_services trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
																data-id="9e54fd2" data-element_type="widget" data-widget_type="trx_sc_services.default">
																<div className="elementor-widget-container">
																	<div className="sc_services sc_services_qw-stylish sc_services_featured_top">
																		<div
																			className="sc_services_columns_wrap sc_item_columns sc_item_posts_container sc_item_columns_4 trx_addons_columns_wrap columns_padding_bottom columns_in_single_row">
																			<div className="trx_addons_column-1_4 trx_addons_column-1_2-tablet ">
																				<div data-post-id="4935"
																					className="sc_services_item sc_item_container post_container with_more with_content with_image sc_services_item_featured_none post-4935 cpt_services type-cpt_services status-publish has-post-thumbnail hentry cpt_services_group-what-we-do">
																					<div className="sc_services_item_content " style={{ backgroundImage: `url("/images/services/slider-sec-5.jpg")` }}>
																						<div className="sc_services_item_content_inner">
																							<div className="sc_services_item_content_inner_bottom">
																								<div className="sc_services_item_info_wrap">
																									<div className="sc_services_item_title_wrap">

																										<h4 className="sc_services_item_title">
																											<a
																												href="#">Investing & Saving </a>
																										</h4>
																									</div>
																									<div className="sc_services_item_button sc_item_button"> <a
																										href="#"
																										className="sc_services_item_more_link"> <span className="link_text">Read
																											more</span><span className="link_icon" /> </a>
																									</div>
																								</div>
																								<div className="sc_services_item_text">
																									<p>Grow wealth steadily through smart investments. </p>
																								</div>
																							</div>
																						</div><a className="sc_services_item_link"
																							href="#" />
																					</div>
																				</div>
																			</div>
																			<div className="trx_addons_column-1_4 trx_addons_column-1_2-tablet ">
																				<div data-post-id="4936"
																					className="sc_services_item sc_item_container post_container with_more with_content with_image sc_services_item_featured_none post-4936 cpt_services type-cpt_services status-publish has-post-thumbnail hentry cpt_services_group-what-we-do">
																					<div className="sc_services_item_content "
																						style={{ backgroundImage: `url("/images/services/slider-sec-6.jpg")` }}>
																						<div className="sc_services_item_content_inner">
																							<div className="sc_services_item_content_inner_bottom">
																								<div className="sc_services_item_info_wrap">
																									<div className="sc_services_item_title_wrap">

																										<h4 className="sc_services_item_title">
																											<a href="/mortgage-services">Mortgage Services </a>
																										</h4>
																									</div>
																									<div className="sc_services_item_button sc_item_button"> <a
																										href="/mortgage-services"
																										className="sc_services_item_more_link"> <span className="link_text">Read
																											more</span><span className="link_icon" /> </a>
																									</div>
																								</div>
																								<div className="sc_services_item_text">
																									<p>Find the right mortgage that fits your dream home plans. </p>
																								</div>
																							</div>
																						</div><a className="sc_services_item_link"
																							href="#" />
																					</div>
																				</div>
																			</div>
																			<div className="trx_addons_column-1_4 trx_addons_column-1_2-tablet ">
																				<div data-post-id="4934"
																					className="sc_services_item sc_item_container post_container with_more with_content with_image sc_services_item_featured_none post-4934 cpt_services type-cpt_services status-publish has-post-thumbnail hentry cpt_services_group-what-we-do">
																					<div className="sc_services_item_content "
																						style={{ backgroundImage: `url("/images/services/slider-sec-7.jpg")` }}>
																						<div className="sc_services_item_content_inner">
																							<div className="sc_services_item_content_inner_bottom">
																								<div className="sc_services_item_info_wrap">
																									<div className="sc_services_item_title_wrap">

																										<h4 className="sc_services_item_title">
																											<a href="/home/services/agency-evolution/">Pension & Retirement </a>
																										</h4>
																									</div>
																									<div className="sc_services_item_button sc_item_button"> <a
																										href="#"
																										className="sc_services_item_more_link"> <span className="link_text">Read
																											more</span><span className="link_icon" /> </a>
																									</div>
																								</div>
																								<div className="sc_services_item_text">
																									<p>Secure your financial future with retirement planning. </p>
																								</div>
																							</div>
																						</div><a className="sc_services_item_link"
																							href="#" />
																					</div>
																				</div>
																			</div>
																			<div className="trx_addons_column-1_4 trx_addons_column-1_2-tablet ">
																				<div data-post-id="4080"
																					className="sc_services_item sc_item_container post_container with_more with_content with_image sc_services_item_featured_none post-4080 cpt_services type-cpt_services status-publish has-post-thumbnail hentry cpt_services_group-what-we-do">
																					<div className="sc_services_item_content" style={{ backgroundImage: `url("/images/services/slider-sec-8.jpg")` }}>
																						<div className="sc_services_item_content_inner">
																							<div className="sc_services_item_content_inner_bottom">
																								<div className="sc_services_item_info_wrap">
																									<div className="sc_services_item_title_wrap">

																										<h4 className="sc_services_item_title">
																											<a href="/home/services/maximizing-returns/">Insurance Services </a>
																										</h4>
																									</div>
																									<div className="sc_services_item_button sc_item_button"> <a
																										href="#"
																										className="sc_services_item_more_link"> <span className="link_text">Read
																											more</span><span className="link_icon" /> </a>
																									</div>
																								</div>
																								<div className="sc_services_item_text">
																									<p>Protect your family with comprehensive insurance. </p>
																								</div>
																							</div>
																						</div><a className="sc_services_item_link"
																							href="#" />
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</section>


										</div>
									</div>

								</article>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div id="page" className="hfeed site">


				<div data-elementor-type="wp-post" data-elementor-id="199" className="elementor elementor-199">

					<section className="elementor-section elementor-top-section elementor-element elementor-element-96fe4f3 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="96fe4f3" data-element_type="section">
						<div className="elementor-container elementor-column-gap-no">
							<div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ad85143" data-id="ad85143" data-element_type="column">
								<div className="elementor-widget-wrap elementor-element-populated">
									<section className="elementor-section elementor-inner-section elementor-element elementor-element-3c2a120 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="3c2a120" data-element_type="section">
										<div className="elementor-container elementor-column-gap-no">
											<div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-5417f78" data-id="5417f78" data-element_type="column">
												<div className="elementor-widget-wrap elementor-element-populated">
													<div className="elementor-element elementor-element-e9c2610 elementor-widget elementor-widget-heading" data-id="e9c2610" data-element_type="widget" data-widget_type="heading.default">
														<div className="elementor-widget-container">
															<h2 className="elementor-heading-title elementor-size-default">We Design a Future of Financial Success</h2>		</div>
													</div>
												</div>
											</div>
										</div>
									</section>
									<section className="elementor-section elementor-inner-section elementor-element elementor-element-632dba9 animated-fast elementor-section-boxed elementor-section-height-default elementor-section-height-default animated fadeInUp" data-id="632dba9" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_mobile&quot;:&quot;none&quot;}">
										<div className="elementor-container elementor-column-gap-no">
											<div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-77a581b" data-id="77a581b" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
												<div className="elementor-widget-wrap elementor-element-populated">
													<div className="elementor-background-overlay" />
													<div className="elementor-element elementor-element-57d96e3 elementor-widget elementor-widget-heading" data-id="57d96e3" data-element_type="widget" data-widget_type="heading.default">
														<div className="elementor-widget-container">
															<h4 className="elementor-heading-title elementor-size-default">Trusted Partner for Investment Success</h4>		</div>
													</div>
													<div className="elementor-element elementor-element-f392c11 elementor-widget elementor-widget-text-editor" data-id="f392c11" data-element_type="widget" data-widget_type="text-editor.default">
														<div className="elementor-widget-container">
															<link rel="stylesheet" href="css/style32.css" />				<p>We guide you through strategic investments, ensuring your portfolio grows with confidence and expertise. </p>						</div>
													</div>
													<div className="elementor-element elementor-element-79a0e0c elementor-widget__width-initial elementor-absolute elementor-widget elementor-widget-image" data-id="79a0e0c" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="image.default">
														<div className="elementor-widget-container">
															{/* <img decoding="async" width="420" height="420" src="/images/services/sec-3-1.png" className="attachment-full size-full wp-image-19378" alt="" data-savepage-srcset="/images/services/sec-3-1.png 420w, https://elementor.nathatype.com/ovesment/wp-content/uploads/sites/3/2022/06/businessman-2021-08-29-13-40-35-utc_isolated-e1685509384462-300x300.png 300w, https://elementor.nathatype.com/ovesment/wp-content/uploads/sites/3/2022/06/businessman-2021-08-29-13-40-35-utc_isolated-e1685509384462-150x150.png 150w" srcset="" sizes="(max-width: 420px) 100vw, 420px" />		*/}
																												</div> 
													</div>
												</div>
											</div>
											<div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-bd3b614" data-id="bd3b614" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
												<div className="elementor-widget-wrap elementor-element-populated">
													<div className="elementor-background-overlay" />
													<div className="elementor-element elementor-element-00fa6f5 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="00fa6f5" data-element_type="widget" data-widget_type="image.default">
														<div className="elementor-widget-container">
															<img decoding="async" src="/images/services/sec-3-2-A.png" title="young-man-discussing-new-mobile-application-during-2021-08-26-19-58-11-utc" alt="young-man-discussing-new-mobile-application-during-2021-08-26-19-58-11-utc" loading="lazy" />															</div>
													</div>
													<div className="elementor-element elementor-element-9562407 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="9562407" data-element_type="widget" data-widget_type="image.default">
														<div className="elementor-widget-container">
															<img decoding="async" src="/images/services/sec-3-2-B.png" title="smiling-bearded-man-discussing-project-at-cafe-tab-2021-09-24-04-17-29-utc" alt="smiling-bearded-man-discussing-project-at-cafe-tab-2021-09-24-04-17-29-utc" loading="lazy" />															</div>
													</div>
													<div className="elementor-element elementor-element-f225c8c elementor-widget elementor-widget-spacer" data-id="f225c8c" data-element_type="widget" data-widget_type="spacer.default">
														<div className="elementor-widget-container">
															<link rel="stylesheet" href="css/style33.css" />		<div className="elementor-spacer">
																<div className="elementor-spacer-inner" />
															</div>
														</div>
													</div>
													<div className="elementor-element elementor-element-fae6d56 elementor-widget__width-initial elementor-widget elementor-widget-heading" data-id="fae6d56" data-element_type="widget" data-widget_type="heading.default">
														<div className="elementor-widget-container">
															<h4 className="elementor-heading-title elementor-size-default">Learn from Expert Financial Advisors</h4>		</div>
													</div>
													<div className="elementor-element elementor-element-5dab090 elementor-widget__width-initial elementor-widget elementor-widget-text-editor" data-id="5dab090" data-element_type="widget" data-widget_type="text-editor.default">
														<div className="elementor-widget-container">
															<p>Gain valuable insights from seasoned professionals who understand the market and help you make informed financial decisions. </p>						</div>
													</div>
												</div>
											</div>
										</div>
									</section>
									<section className="elementor-section elementor-inner-section elementor-element elementor-element-49dd512 animated-fast elementor-section-boxed elementor-section-height-default elementor-section-height-default animated fadeInUp" data-id="49dd512" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_mobile&quot;:&quot;none&quot;}">
										<div className="elementor-container elementor-column-gap-no">
											<div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-487ea56" data-id="487ea56" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
												<div className="elementor-widget-wrap elementor-element-populated">
													<div className="elementor-background-overlay" />
													<div className="elementor-element elementor-element-98ae5f1 elementor-widget elementor-widget-heading" data-id="98ae5f1" data-element_type="widget" data-widget_type="heading.default">
														<div className="elementor-widget-container">
															<h4 className="elementor-heading-title elementor-size-default">Maximising Value Together with You</h4>		</div>
													</div>
													<div className="elementor-element elementor-element-5dab090 elementor-widget__width-initial elementor-widget elementor-widget-text-editor" data-id="5dab090" data-element_type="widget" data-widget_type="text-editor.default" style={{width:"100%"}}>
														<div className="elementor-widget-container">
															<p>We focus on delivering unmatched value, helping you reach your financial goals with personalised solutions.</p></div>
													</div>
													<div className="elementor-element elementor-element-3855820 elementor-widget elementor-widget-progress" data-id="3855820" data-element_type="widget" data-widget_type="progress.default">
														<div className="elementor-widget-container">
															<link rel="stylesheet" href="css/style34.css" />
															<div className="elementor-progress-wrapper" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="100" aria-valuetext="100% (Success metric)">
																<div className="elementor-progress-bar" data-max="100" style={{ width: "100%" }}>
																	<span className="elementor-progress-text">Success Rate</span>
																	<span className="elementor-progress-percentage">100%</span>
																</div>
															</div>
														</div>
													</div>
													<div className="elementor-element elementor-element-ccab2ed elementor-widget elementor-widget-progress" data-id="ccab2ed" data-element_type="widget" data-widget_type="progress.default" style={{marginTop:"0"}}>
														<div className="elementor-widget-container">

															<div className="elementor-progress-wrapper" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="90" aria-valuetext="90% (Settlement metric)">
																<div className="elementor-progress-bar" data-max="90" style={{ width: "98%" }}>
																	<span className="elementor-progress-text">Client Satisfaction</span>
																	<span className="elementor-progress-percentage">98%</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-649e362" data-id="649e362" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
												<div className="elementor-widget-wrap elementor-element-populated">
													<div className="elementor-background-overlay" />
													<div className="elementor-element elementor-element-50e1460 elementor-widget elementor-widget-heading" data-id="50e1460" data-element_type="widget" data-widget_type="heading.default">
														<div className="elementor-widget-container">
															<h4 className="elementor-heading-title elementor-size-default">A Financial Future for Every Generation</h4>		</div>
													</div>
													<div className="elementor-element elementor-element-fa6fca5 elementor-widget elementor-widget-text-editor" data-id="fa6fca5" data-element_type="widget" data-widget_type="text-editor.default">
														<div className="elementor-widget-container">
															<p>We provide personalised financial services, ensuring accessibility and understanding for clients of all ages. </p>						</div>
													</div>
													<div className="elementor-element elementor-element-d2203b3 elementor-widget__width-initial elementor-absolute elementor-widget elementor-widget-image" data-id="d2203b3" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="image.default">
														<div className="elementor-widget-container">
															<img decoding="async" width="764" height="482" src="/images/services/sec-3-4.png" className="attachment-full size-full wp-image-19391" alt="" data-savepage-srcset="/images/services/sec-3-4.png 764w, https://elementor.nathatype.com/ovesment/wp-content/uploads/sites/3/2022/06/phone_screen_group-300x189.png 300w" srcset="" sizes="(max-width: 764px) 100vw, 764px" />															</div>
													</div>
												</div>
											</div>
										</div>
									</section>
								</div>
							</div>
						</div>
					</section>

				</div>



			</div>




			<div className="body_wrap">
				<div className="page_wrap">


					<div className="page_content_wrap">
						<div className="content_wrap_fullscreen">
							<div className="content"> <a id="content_skip_link_anchor" className="zurich_skip_link_anchor" href="#" />
								<article id="post-5059"
									className="post_item_single post_type_page post-5059 page type-page status-publish hentry">
									<div className="post_content entry-content">
										<div data-elementor-type="wp-page" data-elementor-id="5059" className="elementor elementor-5059">




											<section
												className="elementor-section elementor-top-section elementor-element elementor-element-0522bb3 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
												data-id="0522bb3" data-element_type="section">
												<div className="elementor-container elementor-column-gap-extended">
													<div
														className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-05cd153 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
														data-id="05cd153" data-element_type="column">
														<div className="elementor-widget-wrap elementor-element-populated">
															<div
																className="elementor-element elementor-element-03bd0a0 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
																data-id="03bd0a0" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner" />
																	</div>
																</div>
															</div>
															<div
																className="elementor-element elementor-element-4879d09 sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
																data-id="4879d09" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner" />
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</section>
											<section
												className="elementor-section elementor-top-section elementor-element elementor-element-6d782fe elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
												data-id="6d782fe" data-element_type="section"
												data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
												<div className="elementor-container elementor-column-gap-extended">
													<div
														className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3ebe655 sc_column_fixed sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
														data-id="3ebe655" data-element_type="column">
														<div className="elementor-widget-wrap elementor-element-populated">
															<div
																className="elementor-element elementor-element-0e8084b sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
																data-id="0e8084b" data-element_type="widget" data-widget_type="trx_sc_title.default">
																<div className="elementor-widget-container">
																	<div className="sc_title sc_title_default">

																		<h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
																			<span className="sc_item_title_text">Ready to take the next step? </span>
																		</h1>
																		<div className="sc_item_descr sc_title_descr">
																			<p>Reach out to our team of financial experts today. Whether you need personalised advice or have specific questions, we’re here to guide you towards your financial goals. </p>
																		</div>
																		<div
																			className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_item_button_size_normal sc_title_button">
																			<a href="/contact-us/"
																				className="sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
																				<span className="sc_button_text"><span className="sc_button_title">Talk to an Advisor </span>
																				</span></a></div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div
														className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5ec6dcd sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
														data-id="5ec6dcd" data-element_type="column">
														<div className="elementor-widget-wrap elementor-element-populated">
															<div
																className="elementor-element elementor-element-aa523df elementor-widget-divider--view-line sc_fly_static elementor-widget elementor-widget-divider trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
																data-id="aa523df" data-element_type="widget" data-widget_type="divider.default">
																<div className="elementor-widget-container">
																	<div className="elementor-divider">
																		<span className="elementor-divider-separator"> </span>
																	</div>
																</div>
															</div>
															<div
																className="elementor-element elementor-element-eaa6e63 sc_fly_static elementor-widget elementor-widget-toggle trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
																data-id="eaa6e63" data-element_type="widget" data-widget_type="toggle.default">
																<div className="elementor-widget-container">
																	<div className="elementor-toggle">
																		<div className="elementor-toggle-item" onClick={()=>selectTab(0)}>
																			<h5 id="elementor-tab-title-2461" className="elementor-tab-title" data-tab="1"
																				role="button" aria-controls="elementor-tab-content-2461" aria-expanded="false">
																				<span className="elementor-toggle-icon elementor-toggle-icon-right" aria-hidden="true">
																					<span className="elementor-toggle-icon-closed"><svg
																						xmlns="http://www.w3.org/2000/svg"
																						xmlnsXlink="http://www.w3.org/1999/xlink"
																						x="0px"
																						y="0px"
																						viewBox="0 0 14.6 14.5"
																						style={{ enableBackground: "new 0 0 14.6 14.5", xmlSpace: "preserve" }}
																					>
																						<path
																							d="M12.2,7.3l-7.1,7.1c-0.3,0.3-0.8,0.3-1.1,0c0,0,0,0,0,0c-0.3-0.3-0.3-0.8,0-1.1 c0,0,0,0,0,0l6-6l-6-6C3.8,0.9,3.8,0.5,4,0.2c0,0,0,0,0,0c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0L12.2,7.3z"
																						/>
																					</svg>

																					</span> <span className="elementor-toggle-icon-opened"><svg
																						xmlns="http://www.w3.org/2000/svg"
																						x="0px" y="0px" viewBox="0 0 14.6 14.5"
																						style={{ enableBackground: "new 0 0 14.6 14.5", xmlSpace: "preserve" }}
																					>
																						<path
																							d="M7.3,11.6L0.2,4.5c-0.3-0.3-0.3-0.8,0-1.1c0,0,0,0,0,0c0.3-0.3,0.8-0.3,1.1,0 c0,0,0,0,0,0l6,6l6-6c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0c0.3,0.3,0.3,0.8,0,1.1c0,0,0,0,0,0L7.3,11.6z" />
																					</svg></span>
																				</span>
																				<a className="elementor-toggle-title" tabindex="0">What financial services do you offer? </a>
																			</h5>
																			<div id="elementor-tab-content-2461" className="elementor-tab-content elementor-clearfix"
																				data-tab="1" role="region" aria-labelledby="elementor-tab-title-2461" style={tabSelected === 0 ? { display: "block" } : {}}>We offer a range of financial services, including investment management, mortgage advisory, pension planning, retirement advice, and comprehensive insurance solutions. </div>
																		</div>
																		<div className="elementor-toggle-item" onClick={()=>selectTab(1)}>
																			<h5 id="elementor-tab-title-2462" className="elementor-tab-title" data-tab="2"
																				role="button" aria-controls="elementor-tab-content-2462" aria-expanded="false">
																				<span className="elementor-toggle-icon elementor-toggle-icon-right" aria-hidden="true">
																					<span className="elementor-toggle-icon-closed"><svg xmlns="http://www.w3.org/2000/svg"
																						x="0px" y="0px"
																						viewBox="0 0 14.6 14.5" style={{ enableBackground: "new 0 0 14.6 14.5", xmlSpace: "preserve" }}
																					>
																						<path
																							d="M12.2,7.3l-7.1,7.1c-0.3,0.3-0.8,0.3-1.1,0c0,0,0,0,0,0c-0.3-0.3-0.3-0.8,0-1.1 c0,0,0,0,0,0l6-6l-6-6C3.8,0.9,3.8,0.5,4,0.2c0,0,0,0,0,0c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0L12.2,7.3z" />
																					</svg>
																					</span> <span className="elementor-toggle-icon-opened"><svg
																						xmlns="http://www.w3.org/2000/svg"
																						x="0px" y="0px" viewBox="0 0 14.6 14.5"
																						style={{ enableBackground: "new 0 0 14.6 14.5", xmlSpace: "preserve" }}
																					>
																						<path
																							d="M7.3,11.6L0.2,4.5c-0.3-0.3-0.3-0.8,0-1.1c0,0,0,0,0,0c0.3-0.3,0.8-0.3,1.1,0 c0,0,0,0,0,0l6,6l6-6c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0c0.3,0.3,0.3,0.8,0,1.1c0,0,0,0,0,0L7.3,11.6z" />
																					</svg></span>
																				</span>
																				<a className="elementor-toggle-title" tabindex="0">How do I get started with your financial services? </a>
																			</h5>
																			<div id="elementor-tab-content-2462" className="elementor-tab-content elementor-clearfix"
																				data-tab="2" role="region" aria-labelledby="elementor-tab-title-2462" style={tabSelected === 1 ? { display: "block" } : {}}>Getting started is easy! Contact us through our website or give us a call, and we’ll set up a consultation to understand your needs and tailor a financial plan for you. </div>
																		</div>
																		<div className="elementor-toggle-item" onClick={()=>selectTab(2)}>
																			<h5 id="elementor-tab-title-2463" className="elementor-tab-title" data-tab="3"
																				role="button" aria-controls="elementor-tab-content-2463" aria-expanded="false">
																				<span className="elementor-toggle-icon elementor-toggle-icon-right" aria-hidden="true">
																					<span className="elementor-toggle-icon-closed"><svg
																						xmlns="http://www.w3.org/2000/svg"
																						xmlnsXlink="http://www.w3.org/1999/xlink"
																						x="0px"
																						y="0px"
																						viewBox="0 0 14.6 14.5"
																						style={{ enableBackground: "new 0 0 14.6 14.5", xmlSpace: "preserve" }}
																					>
																						<path
																							d="M12.2,7.3l-7.1,7.1c-0.3,0.3-0.8,0.3-1.1,0c0,0,0,0,0,0c-0.3-0.3-0.3-0.8,0-1.1 c0,0,0,0,0,0l6-6l-6-6C3.8,0.9,3.8,0.5,4,0.2c0,0,0,0,0,0c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0L12.2,7.3z"
																						/>
																					</svg>

																					</span> <span className="elementor-toggle-icon-opened"><svg
																						xmlns="http://www.w3.org/2000/svg"
																						x="0px" y="0px" viewBox="0 0 14.6 14.5"
																						style={{ enableBackground: "new 0 0 14.6 14.5", xmlSpace: "preserve" }}
																					>
																						<path
																							d="M7.3,11.6L0.2,4.5c-0.3-0.3-0.3-0.8,0-1.1c0,0,0,0,0,0c0.3-0.3,0.8-0.3,1.1,0 c0,0,0,0,0,0l6,6l6-6c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0c0.3,0.3,0.3,0.8,0,1.1c0,0,0,0,0,0L7.3,11.6z" />
																					</svg></span>
																				</span>
																				<a className="elementor-toggle-title" tabindex="0">Can I customise the financial services to fit my needs? </a>
																			</h5>
																			<div id="elementor-tab-content-2463" className="elementor-tab-content elementor-clearfix"
																				data-tab="3" role="region" aria-labelledby="elementor-tab-title-2463" style={tabSelected === 2 ? { display: "block" } : {}}>Yes, all our services are fully customisable. We work closely with you to create a personalised plan that aligns with your financial goals and future aspirations. </div>
																		</div>
																		<div className="elementor-toggle-item" onClick={()=>selectTab(3)}>
																			<h5 id="elementor-tab-title-2464" className="elementor-tab-title" data-tab="4"
																				role="button" aria-controls="elementor-tab-content-2464" aria-expanded="false">
																				<span className="elementor-toggle-icon elementor-toggle-icon-right" aria-hidden="true">
																					<span className="elementor-toggle-icon-closed"><svg
																						xmlns="http://www.w3.org/2000/svg"
																						xmlnsXlink="http://www.w3.org/1999/xlink"
																						x="0px"
																						y="0px"
																						viewBox="0 0 14.6 14.5"
																						style={{ enableBackground: "new 0 0 14.6 14.5", xmlSpace: "preserve" }}
																					>
																						<path
																							d="M12.2,7.3l-7.1,7.1c-0.3,0.3-0.8,0.3-1.1,0c0,0,0,0,0,0c-0.3-0.3-0.3-0.8,0-1.1 c0,0,0,0,0,0l6-6l-6-6C3.8,0.9,3.8,0.5,4,0.2c0,0,0,0,0,0c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0L12.2,7.3z"
																						/>
																					</svg>

																					</span> <span className="elementor-toggle-icon-opened"><svg
																						xmlns="http://www.w3.org/2000/svg"
																						x="0px" y="0px" viewBox="0 0 14.6 14.5"
																						style={{ enableBackground: "new 0 0 14.6 14.5", xmlSpace: "preserve" }}
																					>
																						<path
																							d="M7.3,11.6L0.2,4.5c-0.3-0.3-0.3-0.8,0-1.1c0,0,0,0,0,0c0.3-0.3,0.8-0.3,1.1,0 c0,0,0,0,0,0l6,6l6-6c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0c0.3,0.3,0.3,0.8,0,1.1c0,0,0,0,0,0L7.3,11.6z" />
																					</svg></span>
																				</span>
																				<a className="elementor-toggle-title" tabindex="0">What makes your financial services unique? </a>
																			</h5>
																			<div id="elementor-tab-content-2464" className="elementor-tab-content elementor-clearfix"
																				data-tab="4" role="region" aria-labelledby="elementor-tab-title-2464" style={tabSelected === 3 ? { display: "block" } : {}}>We offer expert advice backed by years of experience, focusing on personalising financial strategies for each client. Our team prioritises long-term growth, stability, and customer satisfaction. </div>
																		</div>
																		<div className="elementor-toggle-item" onClick={()=>selectTab(4)}>
																			<h5 id="elementor-tab-title-2465" className="elementor-tab-title" data-tab="5"
																				role="button" aria-controls="elementor-tab-content-2465" aria-expanded="false">
																				<span className="elementor-toggle-icon elementor-toggle-icon-right" aria-hidden="true">
																					<span className="elementor-toggle-icon-closed"><svg
																						xmlns="http://www.w3.org/2000/svg"
																						xmlnsXlink="http://www.w3.org/1999/xlink"
																						x="0px"
																						y="0px"
																						viewBox="0 0 14.6 14.5"
																						style={{ enableBackground: "new 0 0 14.6 14.5", xmlSpace: "preserve" }}
																					>
																						<path
																							d="M12.2,7.3l-7.1,7.1c-0.3,0.3-0.8,0.3-1.1,0c0,0,0,0,0,0c-0.3-0.3-0.3-0.8,0-1.1 c0,0,0,0,0,0l6-6l-6-6C3.8,0.9,3.8,0.5,4,0.2c0,0,0,0,0,0c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0L12.2,7.3z"
																						/>
																					</svg>

																					</span> <span className="elementor-toggle-icon-opened"><svg
																						xmlns="http://www.w3.org/2000/svg"
																						x="0px" y="0px" viewBox="0 0 14.6 14.5"
																						style={{ enableBackground: "new 0 0 14.6 14.5", xmlSpace: "preserve" }}
																					>
																						<path
																							d="M7.3,11.6L0.2,4.5c-0.3-0.3-0.3-0.8,0-1.1c0,0,0,0,0,0c0.3-0.3,0.8-0.3,1.1,0 c0,0,0,0,0,0l6,6l6-6c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0c0.3,0.3,0.3,0.8,0,1.1c0,0,0,0,0,0L7.3,11.6z" />
																					</svg></span>
																				</span>
																				<a className="elementor-toggle-title" tabindex="0">How secure is my financial information with your company? </a>
																			</h5>
																			<div id="elementor-tab-content-2465" className="elementor-tab-content elementor-clearfix"
																				data-tab="5" role="region" aria-labelledby="elementor-tab-title-2465" style={tabSelected === 4 ? { display: "block" } : {}}>We take data privacy very seriously. All your financial information is protected through advanced security protocols, ensuring your details remain confidential and secure at all times. </div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</section>
											<section
												className="elementor-section elementor-top-section elementor-element elementor-element-9ef1fd0 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
												data-id="9ef1fd0" data-element_type="section">
												<div className="elementor-container elementor-column-gap-extended">
													<div
														className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c654246 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
														data-id="c654246" data-element_type="column">
														<div className="elementor-widget-wrap elementor-element-populated">
															<div
																className="elementor-element elementor-element-459e023 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
																data-id="459e023" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner" />
																	</div>
																</div>
															</div>
															<div
																className="elementor-element elementor-element-24b3134 sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_layouts_hide_on_notebook sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
																data-id="24b3134" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner" />
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</section>



										</div>
									</div>

								</article>
							</div>
						</div>
					</div>
				</div>
			</div>







			</div>

	);
}

export default TesterPage