import React from 'react'
import { useState, useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";

import Header from './Header/Header.js';
import Footer from './Footer/Footer.js'
import './Layout.css';
import '../common-component/css/vendors/bootstrap.css';
import '../common-component/css/vendors/aos.css';
import '../common-component/css/vendors/remixicon.css';
import '../common-component/css/style.css';
import '../common-component/css/vendors/swiper-bundle.min.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import '../common-component/css/style.css';

import API from "../../API"
import { UserContext } from '../../authentication/pages/UserContext';


import Home from '../pages/Home/Home';
import Services from '../pages/Services/Services';

import MortgageServices from '../pages/ServicesSubPages/MortgageServices';
// import Services from '../pages/Services/Services';
// import Services from '../pages/Services/Services';
// import Services from '../pages/Services/Services';
// import Services from '../pages/Services/Services';
// import Services from '../pages/Services/Services';
// import Services from '../pages/Services/Services';
// import Services from '../pages/Services/Services';


import TesterPage from '../pages/TesterPage/TesterPage';

import Blogs from '../pages/Blogs/Blogs';

import ContactUs from '../pages/ContactUs/ContactUs';
import FAQ from '../pages/FAQ/FAQ';








import Profile from '../pages/Profile/Profile';


import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import CookiePolicy from '../pages/CookiePolicy/CookiePolicy';
import TermsAndConditions from '../pages/TermsAndConditions/TermsAndConditions';


import NoPage from '../pages/NoPage/NoPage';


import { Navigate, useNavigate } from 'react-router-dom';
import { fetchBrowserCurrentLocation, getAddressFromLatLng } from '../../GeneralFunctions';
import Aboutus from '../pages/AboutUs/AboutUs';
import ProfileSettings from '../pages/ProfileSettings/ProfileSettings';


const Layout = () => {
  const navigate = useNavigate()

  const [showCookieBar, setShowCookieBar] = useState(true)
  const [userData, setUserData] = useState(null);
  const [notificationData, setNotificationData] = useState(null);

  const [navSelected, setNavSelected] = useState(null)

  const [isNotificatioShown, setNotificationShown] = useState(false)

  const [isNotificationSettingsShow, setNotificationSettingsShow] = useState(false)
  const [isSecurityModalShow, setSecurityModalShow] = useState(false)

  const [navOpen, setnavOpen] = useState(false);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false)
  const { isLoggedIn, setIsLoggedIn, login, logout } = useContext(UserContext);

  const [categorySelected, selectCategory] = useState(null);
  const [subCategorySelected, selectSubCategory] = useState(null);
  const [cuisineSelected, selectCuisine] = useState(null);
  const [brandSelected, selectBrand] = useState(null);
  const [tripwiseAvailability, setTripwiseAvailability] = useState(null);
  const [searchKey, setSearchKey] = useState(null);

  const [activePage, setActivePage] = useState("home");
  const [cartItems, setCartItems] = useState([]);
  // Location
  const [location, setLocation] = useState({ address: 'Location', current_latitude: null, current_longitude: null });


  const navLeft = () => {
    setNavSelected(null)

  }

  const loadUserData = () => {
    API.get(`/user/users/${window.localStorage.getItem('userID')}/`)
      .then(response => {
        setUserData(response.data)
      })
      .catch(error => {
        console.error(error);
      });

  }

  const loadNotificatiosData = () => {

    API.get(`notifications/${window.localStorage.getItem('userID')}/`)
      .then(response => {
        setNotificationData(response.data)
        console.log("notification data from layout", response.data)
      }
      )

      .catch(error => {
        console.error(error);
      })
  }


  useEffect(() => {
    loadUserData();
    loadNotificatiosData()
  }, [isLoggedIn])

  const loadCartDataForHeader = () => {
    if (isLoggedIn) {
      API.get(`/order/cart/`)
        .then(response => {
          setCartItems(response.data.cart_items)
        })
        .catch(error => {
          // setMessage(error.response?.data?.message || error.message)
          // setIsErrorModalOpen(true)
          // setIsLoading(false)
        });
    }
  }


  const loadLocationForHeader = () => {
    if (isLoggedIn && userData?.current_latitude && userData?.current_longitude) {

      getAddressFromLatLng(userData.current_latitude, userData.current_longitude)
        .then(address => {
          setLocation({ address: address, current_latitude: userData.current_latitude, current_longitude: userData.current_longitude });
        })
        .catch(error => {
          setLocation({ address: 'Location', current_latitude: userData.current_latitude, current_longitude: userData.current_longitude });
        });
    }
    else {
      if (localStorage.getItem('lat') && localStorage.getItem('lat') !== 'null') {
        setLocation({
          address: localStorage.getItem('location_address') || 'Location',
          current_latitude: localStorage.getItem('lat'),
          current_longitude: localStorage.getItem('lng')
        });
      }
      else {
        fetchBrowserCurrentLocation()
          .then(location => {
            setLocation({ address: location.address, current_latitude: location.lat, current_longitude: location.lng });
          })
          .catch(error => {
            setLocation({ address: 'Location', current_latitude: null, current_longitude: null })
          });
      }
    }
  }

  useEffect(() => {
    loadCartDataForHeader();
    loadLocationForHeader();
  }, [userData, isLoggedIn]);

  useEffect(() => {
    if (location.current_latitude) {
      localStorage.setItem('location_address', location.address);
      localStorage.setItem('lat', location.current_latitude);
      localStorage.setItem('lng', location.current_longitude);
    }
  }, [location]);


  return (

    <>

      <Header userData={userData} loadUserData={loadUserData} isLoggedIn={isLoggedIn} setSearchKey={setSearchKey} selectCategory={selectCategory}
        cartItems={cartItems} location={location} setLocation={setLocation}></Header>

      <div className='app-content'>
        <Routes>
          <Route index element={<Navigate to="/home" />} />

          <Route path="home" element={<Home setSearchKey={setSearchKey} selectCategory={selectCategory} location={location} />} />
          <Route path="services" element={<Services />} />
          
          <Route path="mortgage-services" element={<MortgageServices />} />
          {/* <Route path="services" element={<Services />} />
          <Route path="services" element={<Services />} />
          <Route path="services" element={<Services />} />
          <Route path="services" element={<Services />} />
          <Route path="services" element={<Services />} />
          <Route path="services" element={<Services />} />
          <Route path="services" element={<Services />} /> */}


          <Route path="profile" element={<Profile loadUserDataForHeader={loadUserData} />} />
 <Route path="profile-settings" element={<ProfileSettings userData={userData} loadUserDataForHeader={loadUserData} />} />
         


          

             <Route path="blogs" element={<Blogs mode={'list'} />} />
          <Route path="blog-details" element={<Blogs mode={'detail'} />} />

          <Route path="faq" element={<FAQ />} />
          <Route path="about-us" element={<Aboutus />} />


          <Route path="contact-us" element={<ContactUs />} />
         
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="cookie-policy" element={<CookiePolicy />} />


          <Route path="tester" element={<TesterPage />} />

          <Route path="*" element={<NoPage />} />

        </Routes>

      </div>
      <Footer></Footer>


      {/* <div className="theme-btns">
        <button type="button" className="btntheme mode-change-button">
          <i id="themeIcon" className="ri-moon-line icon mode-icon"></i>
          <span className="text-value">Dark</span>
        </button>
        <button type="button" id="rtl-btn" className="btntheme rtlBtnEl">
          <i className="ri-repeat-line icon"></i>
          <span className="text-value">RTL</span>
        </button>
      </div>
      <button className="scroll scroll-to-top menu-page">
        <i className="ri-arrow-up-s-line arrow"></i>
      </button> */}
    </>
  )
}


export default Layout